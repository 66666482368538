/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import api from "../api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, Table, Button, Modal, Select } from "antd";
import Column from "antd/es/table/Column";
import { useGlobalState } from "../components/UserContext";
import Footer from "../components/Footer";
import BottomNavbar from "../components/BottomNavbar";

const Admin = () => {
  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState(null);

  const [menus, setMenus] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [newMenuModal, setNewMenuModal] = useState(false);
  const [newMenuCreating, setNewMenuCreating] = useState(false);
  const [title, setTitle] = useState("");
  const [file, setFile] = useState(null);
  const [deleteMenuModal, setDeleteMenuModal] = useState(false);
  const [menuDeleting, setMenuDeleting] = useState(false);

  const [people, setPeople] = useState([]);
  const [selectedPeople, setSelectedPeople] = useState(null);
  const [deletePeopleModal, setDeletePeopleModal] = useState(false);
  const [peopleDeleting, setPeopleDeleting] = useState(false);
  const [editPeopleModal, setEditPeopleModal] = useState(false);
  const [newPeopleModal, setNewPeopleModal] = useState(false);
  const [peopleEditing, setPeopleEditing] = useState(false);
  const [peopleCreating, setPeopleCreating] = useState(false);
  const [people_panel, setPeoplePanel] = useState("");
  const [people_name, setPeopleName] = useState("");
  const [people_designation, setPeopleDesignation] = useState("");
  const [people_institution, setPeopleInstitution] = useState("");
  const [people_profile, setPeopleProfile] = useState("");
  const [people_email, setPeopleEmail] = useState("");
  const [people_subject, setPeopleSubject] = useState("");
  const [people_serial, setPeopleSerial] = useState("");
  const [people_photo, setPeoplePhoto] = useState(null);

  const [editPeopleData, setEditPeopleData] = useState(null);

  const getReports = () => {
    api
      .get("/reports.php", {
        headers: {
          Authorization: localStorage.getItem("jwt"),
        },
      })
      .then((response) => {
        setLoading(false);
        setReports(response.data);
        console.log(response.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getMenus = () => {
    api
      .get("/acMenu.php")
      .then((response) => {
        setMenus(response.data);
      })
      .catch((error) => {
        console.log("Menus", error);
      });
  };

  const createMenu = () => {
    setNewMenuCreating(true);
    const formData = new FormData();
    formData.append("title", title);
    formData.append("attachment", file);
    api
      .post("/acMenu.php", formData, {
        headers: {
          Authorization: localStorage.getItem("jwt"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setNewMenuModal(false);
        getMenus();
        setNewMenuCreating(false);
      })
      .catch((error) => {
        console.log("Menu", error);
      });
  };

  const deleteMenu = () => {
    setMenuDeleting(true);
    api
      .delete(`/acMenu.php?id=${selectedMenu}`, {
        headers: {
          Authorization: localStorage.getItem("jwt"),
        },
      })
      .then((response) => {
        setDeleteMenuModal(false);
        getMenus();
        setMenuDeleting(false);
      });
  };

  const getPeople = () => {
    api
      .get("/committee.php")
      .then((response) => {
        console.log(response.data);
        let instructor = response.data.instructors;
        instructor.sort((a, b) => a.SERIAL - b.SERIAL);

        let co_instructor = response.data.co_instructors;
        co_instructor.sort((a, b) => a.SERIAL - b.SERIAL);

        let ta = response.data.ta;
        ta.sort((a, b) => a.SERIAL - b.SERIAL);
        
        let coordinator = response.data.coordinator;
        coordinator.sort((a, b) => a.SERIAL - b.SERIAL);

        let people = [];

        instructor.forEach((person) => {
          people.push({
            id: person.ID,
            NAME: person.NAME,
            DESIGNATION: person.DESIGNATION,
            INSTITUTION: person.INSTITUTION,
            PANEL: person.PANEL,
            PROFILE: person.PROFILE,
            EMAIL: person.EMAIL,
            PHOTO: person.PHOTO,
            SUBJECT: person.SUBJECT,
            SERIAL: person.SERIAL
          });
        });

        


        co_instructor.forEach((person) => {
          people.push({
            id: person.ID,
            NAME: person.NAME,
            DESIGNATION: person.DESIGNATION,
            INSTITUTION: person.INSTITUTION,
            PANEL: person.PANEL,
            PROFILE: person.PROFILE,
            EMAIL: person.EMAIL,
            PHOTO: person.PHOTO,
            SUBJECT: person.SUBJECT,
            SERIAL: person.SERIAL
          });
        });


        ta.forEach((person) => {
          people.push({
            id: person.ID,
            NAME: person.NAME,
            DESIGNATION: person.DESIGNATION,
            INSTITUTION: person.INSTITUTION,
            PANEL: person.PANEL,
            PROFILE: person.PROFILE,
            EMAIL: person.EMAIL,
            PHOTO: person.PHOTO,
            SUBJECT: person.SUBJECT,
            SERIAL: person.SERIAL
          });
        });



        coordinator.forEach((person) => {
          people.push({
            id: person.ID,
            NAME: person.NAME,
            DESIGNATION: person.DESIGNATION,
            INSTITUTION: person.INSTITUTION,
            PANEL: person.PANEL,
            PROFILE: person.PROFILE,
            EMAIL: person.EMAIL,
            PHOTO: person.PHOTO,
            SUBJECT: person.SUBJECT,
            SERIAL: person.SERIAL
          });
        });

        setPeople(people);
      })
      .catch((error) => {
        console.log("People", error);
      });
  };

  const createPeople = () => {
    const formData = new FormData();
    formData.append("panel", people_panel);
    formData.append("name", people_name);
    formData.append("designation", people_designation);
    formData.append("institution", people_institution);
    formData.append("profile", people_profile);
    formData.append("email", people_email);
    formData.append("photo", people_photo);
    formData.append("subject", people_subject);
    formData.append("serial", people_serial);

    api
      .post("/committee.php", formData, {
        headers: {
          Authorization: localStorage.getItem("jwt"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setNewPeopleModal(false);
        getPeople();
      })
      .catch((error) => {
        console.log("People", error);
      });
  };

  const deletePeople = () => {
    setPeopleDeleting(true);
    api
      .delete(`/committee.php?id=${selectedPeople}`, {
        headers: {
          Authorization: localStorage.getItem("jwt"),
        },
      })
      .then((response) => {
        setDeletePeopleModal(false);
        getPeople();
        setPeopleDeleting(false);
      });
  };

  const editPeople = () => {
    setPeopleEditing(true);
    const formData = new FormData();
    formData.append("panel", people_panel);
    formData.append("name", people_name);
    formData.append("designation", people_designation);
    formData.append("institution", people_institution);
    formData.append("profile", people_profile);
    formData.append("email", people_email);
    formData.append("subject", people_subject);
    formData.append("serial", people_serial);
    formData.append("id", selectedPeople);

    if (people_photo) formData.append("photo", people_photo);

    api
      .post(`/editCommittee.php`, formData, {
        headers: {
          Authorization: localStorage.getItem("jwt"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setEditPeopleModal(false);
        getPeople();
        setPeopleEditing(false);
      })
      .catch((error) => {
        console.log("People", error);
      });
  };
  useEffect(() => {
    getReports();
    getMenus();
    getPeople();
  }, []);

  if (loading) return <br />;
  else
    return (
      <div className="bg-white relative min-h-screen h-full dark:bg-gray-900 flex flex-col">
        <Navbar />
        <BottomNavbar />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          theme="colored"
        />
        {/* Academic Menu */}
        <div className="mt-6 lg:mt-16 mb-8 flex items-center">
          <hr className="w-1/6 border border-black dark:border-gray-400"></hr>
          <div className="text-xl lg:text-3xl mx-2 dark:text-gray-400">
            Menu
          </div>
          <hr className="w-5/6 border border-black dark:border-gray-400"></hr>
        </div>

        <div>
          <div className="flex flex-row justify-end gap-5 mb-10">
            <div>
              <Button
                onClick={() => setNewMenuModal(true)}
                type="primary"
                className="text-white"
              >
                New Menu Item
              </Button>
            </div>
          </div>
        </div>

        <div className="mt-6 lg:mt-16 mx-20 mb-20 lg:mx-40 relative">
          <Table dataSource={menus} rowKey="id" style={{ overflowX: "auto" }}>
            <Column title="Title" dataIndex="title"></Column>
            <Column
              title="Attachment"
              dataIndex="attachment"
              render={(text, record) => (
                <div>
                  {text ? (
                    <Button
                      onClick={() => {
                        window.location.href = text;
                      }}
                    >
                      Download
                    </Button>
                  ) : (
                    <div>No attachment</div>
                  )}
                </div>
              )}
            ></Column>
            <Column
              title="Action"
              dataIndex="id"
              render={(text, record) => (
                <>
                  <Button
                    onClick={() => {
                      setSelectedMenu(text);
                      setDeleteMenuModal(true);
                    }}
                    danger
                  >
                    Delete
                  </Button>
                </>
              )}
            ></Column>
          </Table>
        </div>

        {/* New Menu Modal */}
        <Modal
          title="New Menu Item"
          centered
          open={newMenuModal}
          onOk={() => createMenu()}
          onCancel={() => setNewMenuModal(false)}
          okText={newMenuCreating ? "Creating" : "Create"}
        >
          <div className="">
            <div className="relative z-0 w-full my-6 group">
              <input
                type="text"
                name="title"
                id="title"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                required
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <label
                htmlFor="title"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
              >
                Menu Title
              </label>
            </div>

            {/* File */}
            <div className="relative z-0 w-full my-6 group">
              <input
                type="file"
                name="file"
                id="file"
                required
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                onChange={(e) => {
                  // setFile(e.target.files[0]); // gives error
                  setFile(e.target.files[0]);
                }}
              />
              <label
                htmlFor="file"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
              >
                Attachment
              </label>
            </div>
          </div>
        </Modal>

        <Modal
          title="Delete Menu Item"
          centered
          open={deleteMenuModal}
          onOk={() => deleteMenu()}
          onCancel={() => setDeleteMenuModal(false)}
          okText={menuDeleting ? "Deleting" : "Delete"}
        >
          <div className="">Are you sure want to delete?</div>
        </Modal>

        {/* Committee */}
        <div className="mt-6 lg:mt-16 mb-8 flex items-center">
          <hr className="w-1/6 border border-black dark:border-gray-400"></hr>
          <div className="text-xl lg:text-3xl mx-2 dark:text-gray-400">
            People
          </div>
          <hr className="w-5/6 border border-black dark:border-gray-400"></hr>
        </div>

        <div>
          <div className="flex flex-row justify-end gap-5 mb-10">
            <div>
              <Button
                onClick={() => setNewPeopleModal(true)}
                type="primary"
                className="text-white"
              >
                New Committee Member
              </Button>
            </div>
          </div>
        </div>

        <div className="mt-6 lg:mt-16 mx-20 mb-20 lg:mx-40 relative">
          <Table
            dataSource={people}
            rowKey="id"
            style={{ overflowX: "auto" }}
            pagination={false}
          >
            <Column title="Serial" dataIndex="SERIAL"></Column>

            <Column
              title="Photo"
              dataIndex="PHOTO"
              render={(text, record) => (
                <img
                  src={text}
                  alt={record.NAME}
                  className="w-20 h-20 rounded-full mx-auto"
                />
              )}
            ></Column>
            <Column title="Name" dataIndex="NAME"></Column>
            <Column title="Panel" dataIndex="PANEL"></Column>
            <Column title="Designation" dataIndex="DESIGNATION"></Column>
            <Column title="Institution" dataIndex="INSTITUTION"></Column>
            <Column title="Profile" dataIndex="PROFILE"></Column>
            <Column title="Email" dataIndex="EMAIL"></Column>
            <Column title="Subject" dataIndex="SUBJECT"></Column>


            <Column
              title="Action"
              dataIndex="id"
              render={(text, record) => (
                <>
                  <Button
                    onClick={() => {
                      setSelectedPeople(text);
                      setEditPeopleModal(true);

                      setPeopleName(record.NAME);
                      setPeoplePanel(record.PANEL);
                      setPeopleDesignation(record.DESIGNATION);
                      setPeopleInstitution(record.INSTITUTION);
                      setPeopleProfile(record.PROFILE);
                      setPeopleEmail(record.EMAIL);
                      setPeoplePhoto(null);
                      setPeopleSubject(record.SUBJECT);
                      setPeopleSerial(record.SERIAL);
                    }}
                  >
                    Edit
                  </Button>

                  <Button
                    onClick={() => {
                      setSelectedPeople(text);
                      setDeletePeopleModal(true);
                    }}
                    danger
                  >
                    Delete
                  </Button>
                </>
              )}
            ></Column>
          </Table>
        </div>

        {/* new people modal */}
        <Modal
          title="New People"
          centered
          open={newPeopleModal}
          onOk={() => createPeople()}
          onCancel={() => setNewPeopleModal(false)}
          okText={newMenuCreating ? "Creating" : "Create"}
        >
          <div className="">
            {/* Name */}
            <div className="relative z-0 w-full my-6 group">
              <input
                type="text"
                name="name"
                id="name"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                required
                value={people_name}
                onChange={(e) => setPeopleName(e.target.value)}
              />
              <label
                htmlFor="name"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
              >
                Name
              </label>
            </div>

            {/* Panel */}
            <div className="relative z-0 w-full my-6 group">
              <label
                htmlFor="panel"
                className="text-sm text-gray-500 dark:text-gray-400"
              >
                Panel
              </label>
              <Select
                onChange={(value) => setPeoplePanel(value)}
                options={[
                  { label: "Instructor", value: "instructors" },
                  { label: "Co-Instructor", value: "co-instructors" },
                  { label: "TA", value: "teaching assistants" },
                  { label: "Coordinator", value: "Co-ordinator committee" },
                ]}
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              />
            </div>

            {/* Subject */}
            <div className="relative z-0 w-full my-6 group">
              <input
                type="text"
                name="subject"
                id="subject"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                required
                value={people_subject}
                onChange={(e) => setPeopleSubject(e.target.value)}
              />
              <label
                htmlFor="subject"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
              >
                Course
              </label>
            </div>


            {/* File */}
            <div className="relative z-0 w-full my-6 group">
              <input
                type="file"
                name="file"
                id="file"
                required
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                onChange={(e) => {
                  // setFile(e.target.files[0]); // gives error
                  setPeoplePhoto(e.target.files[0]);
                }}
              />
              <label
                htmlFor="file"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
              >
                Photo
              </label>
            </div>

            {/* Designation */}
            <div className="relative z-0 w-full my-6 group">
              <input
                type="text"
                name="designation"
                id="designation"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                required
                value={people_designation}
                onChange={(e) => setPeopleDesignation(e.target.value)}
              />
              <label
                htmlFor="designation"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
              >
                Designation
              </label>
            </div>

            {/* Institution */}
            <div className="relative z-0 w-full my-6 group">
              <input
                type="text"
                name="institution"
                id="institution"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                required
                value={people_institution}
                onChange={(e) => setPeopleInstitution(e.target.value)}
              />
              <label
                htmlFor="institution"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
              >
                Institution
              </label>
            </div>

            {/* Profile */}
            <div className="relative z-0 w-full my-6 group">
              <input
                type="text"
                name="profile"
                id="profile"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                required
                value={people_profile}
                onChange={(e) => setPeopleProfile(e.target.value)}
              />
              <label
                htmlFor="profile"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
              >
                Profile
              </label>
            </div>

            {/* Email */}
            <div className="relative z-0 w-full my-6 group">
              <input
                type="email"
                name="email"
                id="email"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                required
                value={people_email}
                onChange={(e) => setPeopleEmail(e.target.value)}
              />
              <label
                htmlFor="email"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
              >
                Email
              </label>
            </div>

            {/* Serial */}
            <div className="relative z-0 w-full my-6 group">
              <input
                type="text"
                name="serial"
                id="serial"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                required
                value={people_serial}
                onChange={(e) => setPeopleSerial(e.target.value)}
              />
              <label
                htmlFor="serial"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
              >
                Serial
              </label>
            </div>
          </div>
        </Modal>

        {/* Delete People Modal */}
        <Modal
          title="Delete People"
          centered
          open={deletePeopleModal}
          onOk={() => deletePeople()}
          onCancel={() => setDeletePeopleModal(false)}
          okText={peopleDeleting ? "Deleting" : "Delete"}
        >
          <div className="">Are you sure want to delete?</div>
        </Modal>

        {/* Edit People Modal */}
        <Modal
          title="Edit People"
          centered
          open={editPeopleModal}
          onOk={() => editPeople()}
          onCancel={() => setEditPeopleModal(false)}
          okText={peopleDeleting ? "Editing" : "Edit"}
        >
          <div className="">
            {/* Name */}
            <div className="relative z-0 w-full my-6 group">
              <input
                type="text"
                name="name"
                id="name"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                required
                value={people_name}
                onChange={(e) => setPeopleName(e.target.value)}
              />
              <label
                htmlFor="name"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
              >
                Name
              </label>
            </div>

            {/* Panel */}
            <div className="relative z-0 w-full my-6 group">
              <label
                htmlFor="panel"
                className="text-sm text-gray-500 dark:text-gray-400"
              >
                Panel
              </label>
              <Select
                defaultValue={people_panel}
                onChange={(value) => setPeoplePanel(value)}
                options={[
                  { label: "Instructor", value: "instructors" },
                  { label: "Co-Instructor", value: "co-instructors" },
                  { label: "TA", value: "teaching assistants" },
                  { label: "Coordinator", value: "Co-ordinator committee" },
                ]}
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              />
            </div>

            {/* Subject */}
            <div className="relative z-0 w-full my-6 group">
              <input
                type="text"
                name="subject"
                id="subject"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                required
                value={people_subject}
                onChange={(e) => setPeopleSubject(e.target.value)}
              />
              <label
                htmlFor="subject"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
              >
                Course
              </label>
            </div>



            {/* File */}
            <div className="relative z-0 w-full my-6 group">
              <input
                type="file"
                name="file"
                id="file"
                required
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                onChange={(e) => {
                  // setFile(e.target.files[0]); // gives error
                  setPeoplePhoto(e.target.files[0]);
                }}
              />
              <label
                htmlFor="file"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
              >
                Photo
              </label>
            </div>

            {/* Designation */}
            <div className="relative z-0 w-full my-6 group">
              <input
                type="text"
                name="designation"
                id="designation"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                required
                value={people_designation}
                onChange={(e) => setPeopleDesignation(e.target.value)}
              />
              <label
                htmlFor="designation"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
              >
                Designation
              </label>
            </div>

            {/* Institution */}
            <div className="relative z-0 w-full my-6 group">
              <input
                type="text"
                name="institution"
                id="institution"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                required
                value={people_institution}
                onChange={(e) => setPeopleInstitution(e.target.value)}
              />
              <label
                htmlFor="institution"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
              >
                Institution
              </label>
            </div>

            {/* Profile */}
            <div className="relative z-0 w-full my-6 group">
              <input
                type="text"
                name="profile"
                id="profile"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                required
                value={people_profile}
                onChange={(e) => setPeopleProfile(e.target.value)}
              />
              <label
                htmlFor="profile"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
              >
                Profile
              </label>
            </div>

            {/* Email */}
            <div className="relative z-0 w-full my-6 group">
              <input
                type="email"
                name="email"
                id="email"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                required
                value={people_email}
                onChange={(e) => setPeopleEmail(e.target.value)}
              />
              <label
                htmlFor="email"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
              >
                Email
              </label>
            </div>

            {/* Serial */}
            <div className="relative z-0 w-full my-6 group">
              <input
                type="text"
                name="serial"
                id="serial"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                required
                value={people_serial}
                onChange={(e) => setPeopleSerial(e.target.value)}
              />
              <label
                htmlFor="serial"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
              >
                Serial
              </label>
            </div>
          </div>
        </Modal>

        {/* Report */}
        <div className="mt-6 lg:mt-16 mb-8 flex items-center">
          <hr className="w-1/6 border border-black dark:border-gray-400"></hr>
          <div className="text-xl lg:text-3xl mx-2 dark:text-gray-400">
            Report
          </div>
          <hr className="w-5/6 border border-black dark:border-gray-400"></hr>
        </div>
        <div className="mb-20 mx-6 lg:mx-24 grid grid-cols-1 gap-x-24 gap-y-3 lg:grid-cols-4 relative">
          <Card title="Accounts opened" bordered={true} key={"Accounts"}>
            <p className="text-5xl text-center">
              {reports.account_opened ? reports.account_opened : "0"}
            </p>
          </Card>
          <Card
            title="Total applications"
            key={"idapplications2"}
            bordered={true}
          >
            <p className="text-5xl text-center">
              {reports.applied ? reports.applied : "0"}
            </p>
          </Card>
          <Card title="Total payments" key={"payments"} bordered={true}>
            <p className="text-5xl text-center">
              {reports.payment_no ? reports.payment_no : "0"}
            </p>
          </Card>
          <Card title="Total amount" key={"amount"} bordered={true}>
            <p className="text-5xl text-center">
              {reports.payment_amount ? reports.payment_amount : "0"}
            </p>
          </Card>
        </div>
        <Footer />
      </div>
    );
};

export default Admin;
