import React, { useEffect } from "react";
import {
  Root,
  List,
  Item,
  Link,
  Trigger,
  Content,
  Indicator,
  Viewport,
} from "@radix-ui/react-navigation-menu";
import classNames from "classnames";
import { CaretDownIcon } from "@radix-ui/react-icons";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useGlobalState } from "./UserContext";
import { Modal } from "antd";
import api from "../api";

const BottomNavbar = () => {
  const nav = useNavigate();
  const [modal2Open, setModal2Open] = useState(false);
  const [user] = useGlobalState("user");
  const [isLoggedIn, setIsLoggedIn] = useGlobalState("isLoggedIn");

  const [menus, setMenus] = useState([]);

  const logout = () => {
    localStorage.removeItem("jwt");
    setIsLoggedIn(false);
    nav("/login");
    window.location.reload();
  };

  useEffect(() => {
    console.log("Getting Menus");
    api
      .get("/acMenu.php")
      .then((response) => {
        setMenus(response.data);
      })
      .catch((error) => {
        console.log("Menus", error);
      });
  }, []);

  return (
    <Root className="sticky hidden md:block z-20 top-0 w-full mx-auto border-b border-gray-200 shadow-sm">
      <List className="flex justify-evenly md:px-32 lg:px-48 bg-white py-3">
        <Item>
          <Link
            className="text-blackA11 hover:bg-white block select-none rounded-[4px] px-3 py-2 text-[15px] font-medium leading-none no-underline outline-none"
            href="/home"
          >
            Home
          </Link>
        </Item>

        <Item>
          <Link
            className="text-blackA11 hover:bg-white block select-none rounded-[4px] px-3 py-2 text-[15px] font-medium leading-none no-underline outline-none"
            href="/notice"
          >
            Notices
          </Link>
        </Item>
        <Item>
          <Trigger className="text-blackA11 hover:bg-white  group flex select-none items-center justify-between gap-[2px] rounded-[4px] px-3 py-2 text-[15px] font-medium leading-none outline-none">
            Academic
            <CaretDownIcon
              className="text-blackA10 relative top-[1px] transition-transform duration-[250] ease-in group-data-[state=open]:-rotate-180"
              aria-hidden
            />
          </Trigger>
          <Content className="data-[motion=from-start]:animate-enterFromLeft data-[motion=from-end]:animate-enterFromRight data-[motion=to-start]:animate-exitToLeft data-[motion=to-end]:animate-exitToRight absolute top-0 left-0 w-full sm:w-auto">
            <ul className="m-0 grid list-none gap-x-[10px] p-[12px] sm:w-[600px] sm:grid-flow-col sm:grid-rows-4 bg-gray-50">
              {menus.map((menu) => (
                <ListItem
                  key={menu.id}
                  title={menu.title}
                  href={menu.attachment}
                >
                  {menu.description}
                </ListItem>
              ))}

              {/* <ListItem
                href="/people"
                title="Instructors & Committee"
              ></ListItem>
              <ListItem
                href="https://ssl.du.ac.bd/fontView/images/syllabus/1687063364OnlyRules.pdf"
                title="Curriculum and Syllabus"
              ></ListItem>

              <ListItem
                href="https://pmics.cse.du.ac.bd/api/files/1st_batch_calender_24.pdf"
                title="1st Batch Academic Calendar"
              ></ListItem>
              <ListItem
                href="https://pmics.cse.du.ac.bd/api/files/2nd_batch_calender_24.pdf"
                title="2nd Batch Class Calendar"
              ></ListItem>

              <ListItem
                href="https://pmics.cse.du.ac.bd/api/files/schedule_1_2_Jan_24.pdf"
                title="1st Batch Class Schedule"
              ></ListItem>
              <ListItem
                href="https://pmics.cse.du.ac.bd/api/files/schedule_2_1_Jan_24.jpeg"
                title="2nd Batch Class Schedule"
              ></ListItem> */}
            </ul>
          </Content>
        </Item>

        <Item>
          <Trigger className="text-blackA11 hover:bg-white group flex select-none items-center justify-between gap-[2px] rounded-[4px] px-3 py-2 text-[15px] font-medium leading-none outline-none">
            Students
            <CaretDownIcon
              className="text-blackA10 relative top-[1px] transition-transform duration-[250] ease-in group-data-[state=open]:-rotate-180"
              aria-hidden
            />
          </Trigger>
          <Content className="data-[motion=from-start]:animate-enterFromLeft data-[motion=from-end]:animate-enterFromRight data-[motion=to-start]:animate-exitToLeft data-[motion=to-end]:animate-exitToRight absolute top-0 left-0 w-full sm:w-auto">
            <ul className="m-0 grid list-none gap-x-[10px] p-[22px] sm:w-[300px] sm:grid-flow-col sm:grid-rows-1 bg-gray-50">
              <ListItem
                title="Batch 1"
                onClick={() => {
                  window.location.href = "/students?batch=1";
                }}
              />

              <ListItem
                title="Batch 2"
                onClick={() => {
                  window.location.href = "/students?batch=2";
                }}
              />

              <ListItem
                title="Batch 3"
                onClick={() => {
                  window.location.href = "/students?batch=3";
                }}
              />
            </ul>
          </Content>
        </Item>

        <Item>
          <Link
            className="text-blackA11 hover:bg-white block select-none rounded-[4px] px-3 py-2 text-[15px] font-medium leading-none no-underline outline-none"
            href="/contact"
          >
            Contact
          </Link>
        </Item>

        <Item>
          <Trigger className="text-blackA11 hover:bg-white group flex select-none items-center justify-between gap-[2px] rounded-[4px] px-3 py-2 text-[15px] font-medium leading-none outline-none">
            Account
            <CaretDownIcon
              className="text-blackA10 relative top-[1px] transition-transform duration-[250] ease-in group-data-[state=open]:-rotate-180"
              aria-hidden
            />
          </Trigger>
          <Content className="data-[motion=from-start]:animate-enterFromLeft data-[motion=from-end]:animate-enterFromRight data-[motion=to-start]:animate-exitToLeft data-[motion=to-end]:animate-exitToRight absolute top-0 left-0 w-full sm:w-auto">
            <ul className="m-0 grid list-none gap-x-[10px] p-[22px] sm:w-[600px] sm:grid-flow-col sm:grid-rows-1 bg-gray-50">
              <ListItem
                title="Profile"
                onClick={() => {
                  user.profile === "1" ? nav("/viewprofile") : nav("/profile");
                }}
              >
                View and edit your profile.
              </ListItem>
              {isLoggedIn ? (
                <ListItem
                  onClick={() => {
                    setModal2Open(true);
                  }}
                  title="Logout"
                >
                  Logout from current session.
                </ListItem>
              ) : (
                <ListItem href="/login" title="Login">
                  Login to apply for the program.
                </ListItem>
              )}
            </ul>
          </Content>
        </Item>

        <Modal
          title="Confirmation"
          style={{ right: 20 }}
          open={modal2Open}
          okText={"Log out"}
          onOk={() => logout()}
          onCancel={() => setModal2Open(false)}
        >
          <div>Are you sure you want to log out?</div>
        </Modal>
        <Indicator className="data-[state=visible]:animate-fadeIn data-[state=hidden]:animate-fadeOut top-full z-[1] flex h-[10px] items-end justify-center overflow-hidden transition-[width,transform_250ms_ease]">
          <div className="relative top-[70%] h-[10px] w-[10px] rotate-[45deg] rounded-tl-[2px] bg-white" />
        </Indicator>
      </List>

      <div className="perspective-[2000px] absolute top-full left-0 flex w-full justify-center">
        <Viewport className="data-[state=open]:animate-scaleIn data-[state=closed]:animate-scaleOut relative mt-[10px] h-[var(--radix-navigation-menu-viewport-height)] w-full origin-[top_center] overflow-hidden rounded-[6px] bg-white transition-[width,_height] duration-300 sm:w-[var(--radix-navigation-menu-viewport-width)]" />
      </div>
    </Root>
  );
};

const ListItem = React.forwardRef(
  ({ className, children, title, ...props }, forwardedRef) => (
    <li>
      <Link asChild>
        <a
          className={classNames(
            "focus:shadow-[0_0_0_2px] hover:bg-mauve3 block select-none rounded-[6px] p-3 text-[15px] leading-none no-underline outline-none transition-colors",
            className
          )}
          {...props}
          ref={forwardedRef}
        >
          <div className="text-blackA12 mb-[5px] font-medium leading-[1.2]">
            {title}
          </div>
          <p className="text-mauve11 leading-[1.4]">{children}</p>
        </a>
      </Link>
    </li>
  )
);

export default BottomNavbar;
