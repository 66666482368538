import React from "react";

const Footer = () => {
  return (
    <div className="bg-gray-100 absolute bottom-0 w-full dark:bg-gray-800 text-black dark:text-white text-center md:font-medium text-xs md:text-lg px-4 py-2 md:py-2">
      © 2023 Department of Computer Science and Engineering, University of
      Dhaka, Dhaka, Bangladesh
    </div>
  );
};

export default Footer;
