import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import api from "../api";
import "../index.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Button, Space, Table } from "antd";
import { Modal } from "antd";
import Column from "antd/es/table/Column";
import { useGlobalState } from "../components/UserContext";
import { useLocation, useNavigate } from "react-router-dom";
import { CgSpinner } from "react-icons/cg";
import Footer from "../components/Footer";

import ReactGA from "react-ga4";
import BottomNavbar from "../components/BottomNavbar";

const Notices = () => {
  const nav = useNavigate();
  const [loading, setLoading] = useState(true);
  const [apiLoading, setApiLoading] = useState(false);
  const [user, setUser] = useGlobalState("user");
  const [isLoggedIn, setIsLoggedIn] = useGlobalState("isLoggedIn");
  const { state } = useLocation();

  const [notices, setNotices] = useState([]);
  const getNotices = async () => {
    setLoading(true);
    api
      .get("/notice.php")
      .then((res) => {
        setNotices(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error fetching notices");
        setLoading(false);
      });
  };

  /* New Notice */
  const [newNoticeModal, setNewNoticeModal] = useState(false);
  const [newNoticeCreating, setNewNoticeCreating] = useState(false);

  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [file, setFile] = useState(null);

  const createNotice = () => {
    console.log("Creating notice");
    setNewNoticeCreating(true);

    const formData = new FormData();
    formData.append("title", title);
    formData.append("body", body);
    formData.append("file", file);

    console.log("File", file);

    api
      .post("/notice.php", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("jwt"),
        },
      })
      .then((res) => {
        setNewNoticeCreating(false);
        setNewNoticeModal(false);
        toast.success("Notice created");
        getNotices();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error creating notice");
        setNewNoticeCreating(false);
      });
  };

  /* Delete Notice */
  const [deleteNoticeModal, setDeleteNoticeModal] = useState(false);
  const [noticeDeletinng, setNoticeDeletinng] = useState(false);
  const [selectedNotice, setSelectedNotice] = useState(null);

  const deleteNotice = () => {
    console.log("Deleting notice");
    setNoticeDeletinng(true);

    api
      .delete(`/notice.php?notice_id=${selectedNotice}`, {
        headers: {
          Authorization: localStorage.getItem("jwt"),
        },
      })
      .then((res) => {
        setNoticeDeletinng(false);
        setDeleteNoticeModal(false);
        toast.success("Notice deleted");
        getNotices();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error deleting notice");
        setNoticeDeletinng(false);
      });
  };

  useEffect(() => {
    getNotices();
    // if (!isLoggedIn) nav("/login", { state: "redirected" });
    // else if (user.verified === "0") nav("/verify");
    // else {
    //   getNotices();

    //   ReactGA.send({
    //     hitType: "pageview",
    //     page: window.location.pathname + window.location.search,
    //     title: "Notices",
    //   });
    // }
  }, []);

  return (
    <div className="bg-white relative min-h-screen h-full dark:bg-gray-900 flex flex-col">
      <Navbar />
      <BottomNavbar />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
        theme="colored"
      />

      {/* Content */}
      <div className="mt-6 lg:mt-16 mx-2 mb-20 lg:mx-10 relative">
        {user.role === "admin" ? (
          <div>
            <div className="flex flex-row justify-end gap-5 mb-10">
              <div>
                <Button
                  onClick={() => setNewNoticeModal(true)}
                  type="primary"
                  className="text-white"
                >
                  New notice
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <Table
          loading={loading}
          dataSource={notices}
          rowKey="APP_ID"
          style={{ overflowX: "auto" }}
        >
          <Column
            title="Title"
            dataIndex="title"
            render={(text, record) => <div className=" font-bold">{text}</div>}
          ></Column>
          <Column title="Body" dataIndex="body"></Column>
          <Column
            title="Attachment"
            dataIndex="file"
            render={(text, record) => (
              <div>
                {text ? (
                  <Button
                    onClick={() => {
                      window.location.href = text;
                    }}
                  >
                    Download
                  </Button>
                ) : (
                  <div>No attachment</div>
                )}
              </div>
            )}
          ></Column>

          {user.role === "admin" ? (
            <>
              <Column
                title="Action"
                dataIndex="id"
                render={(text, record) => (
                  <>
                    <Button
                      onClick={() => {
                        setSelectedNotice(text);
                        setDeleteNoticeModal(true);
                      }}
                      danger
                    >
                      Delete
                    </Button>
                  </>
                )}
              ></Column>
            </>
          ) : (
            <></>
          )}
        </Table>
      </div>

      {/* New Notice Modal */}
      <Modal
        title="Send SMS"
        centered
        open={newNoticeModal}
        onOk={() => createNotice()}
        onCancel={() => setNewNoticeModal(false)}
        okText={newNoticeCreating ? "Creating" : "Create"}
      >
        <div className="">
          <div className="relative z-0 w-full my-6 group">
            <input
              type="text"
              name="title"
              id="title"
              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              required
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <label
              htmlFor="title"
              className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
            >
              Notice Title
            </label>
          </div>

          <div>
            <label
              htmlFor="body"
              className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
            >
              Notice Body
            </label>
            <textarea
              id="body"
              rows="4"
              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Write notice body here..."
              value={body}
              onChange={(e) => setBody(e.target.value)}
            ></textarea>
          </div>

          {/* File */}
          <div className="relative z-0 w-full my-6 group">
            <input
              type="file"
              name="file"
              id="file"
              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              onChange={(e) => {
                // setFile(e.target.files[0]); // gives error
                setFile(e.target.files[0]);
              }}
            />
            <label
              htmlFor="file"
              className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
            >
              Attachment (optional)
            </label>
          </div>
        </div>
      </Modal>

      {/* Delete Notice Modal */}
      <Modal
        title="Send SMS"
        centered
        open={deleteNoticeModal}
        onOk={() => deleteNotice()}
        onCancel={() => setDeleteNoticeModal(false)}
        okText={noticeDeletinng ? "Deleting" : "Delete"}
      >
        <div className="">Are you sure want to delete?</div>
      </Modal>
    </div>
  );
};
export default Notices;
