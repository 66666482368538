/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import api from "../api";
import "../index.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { FcOk, FcCancel, FcDownload, FcTimeline } from "react-icons/fc";
import { BiTimeFive } from "react-icons/bi";
import "react-toastify/dist/ReactToastify.css";
import { Space, Table } from "antd";
import { Modal } from "antd";
import Column from "antd/es/table/Column";
import { useGlobalState } from "../components/UserContext";
import { useLocation, useNavigate } from "react-router-dom";
import { CgSpinner } from "react-icons/cg";
import { GiSandsOfTime } from "react-icons/gi";
import Footer from "../components/Footer";
import Confetti from "react-confetti";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import html2pdf from "html2pdf.js";
import { SiMicrosoftexcel } from "react-icons/si";
import { VscFilePdf } from "react-icons/vsc";
import ReactGA from "react-ga4";
import { subs } from "../subs";
import { unis } from "../universities";
import BottomNavbar from "../components/BottomNavbar";

const Submission = () => {
  const nav = useNavigate();
  const [loading, setLoading] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);
  const [user, setUser] = useGlobalState("user");
  const [isLoggedIn, setIsLoggedIn] = useGlobalState("isLoggedIn");
  const { state } = useLocation();
  const [data, setData] = useState([]);

  const sub_name_from_id = (id) => {
    let sub_name = false;
    subs.forEach((sub) => {
      if (sub.sub_id === parseInt(id)) {
        sub_name = sub.sub_name;
      }
    });
    return sub_name;
  };

  const uni_name_from_id = (id) => {
    let uni_name = false;
    unis.forEach((uni) => {
      if (uni.uni_id === parseInt(id)) {
        uni_name = uni.uni_name;
      }
    });
    return uni_name;
  };

  // Filter
  const [paymentFilter, setPaymentFilter] = useState("");
  const [verifiedFilter, setVerifiedFilter] = useState("");

  function handlePaymentFilterChange(event) {
    const newPaymentFilter = event.target.value;
    setPaymentFilter(newPaymentFilter);
    fetchData(newPaymentFilter, verifiedFilter);
  }

  function handleVerifiedFilterChange(event) {
    const newVerifiedFilter = event.target.value;
    setVerifiedFilter(newVerifiedFilter);
    fetchData(paymentFilter, newVerifiedFilter);
  }

  // Action
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [profile, setProfile] = useState({});

  const [smsModalOpen, setSmsModalOpen] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(false);

  const [smsLoading, setSmsLoading] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);

  const [selectedApp, setSelectedApp] = useState(null);
  const [selectedUID, setSelectedUID] = useState(null);
  const [message, setMessage] = useState("");

  const [resultDownloadModalOpen, setResultDownloadModalOpen] = useState(false);

  // download admit
  const [downloadText, setDownloadText] = useState("Download Admit");
  const downloadAdmit = () => {
    setDownloadText("Downloading");
    api
      .get("/downloadAdmit.php", {
        headers: {
          Authorization: localStorage.getItem("jwt"),
        },
      })
      .then((response) => {
        setDownloadText("Download Admit");
        window.open(response.data["admit"], "_blank");
      })
      .catch((err) => {
        setDownloadText("Download Admit");
        toast.error("Download Failed");
        console.log(err);
      });
  };

  const convertToXLSX = (jsonData) => {
    const worksheet = XLSX.utils.json_to_sheet(jsonData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const xlsxFile = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    return xlsxFile;
  };

  const downloadXLSX = () => {
    const xlsxFile = convertToXLSX(data);
    const file = new Blob([xlsxFile], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(file, "result.xlsx");
  };

  const convertToHTML = (jsonData) => {
    // Convert the JSON data to HTML format
    const html = `
      <html>
        <head>
          <title>Result</title>
          <style>
          body {
            font-family: Arial, sans-serif;
          }
          table {
            border-collapse: collapse;
            width: 100%;
          }
          th, td {
            border: 1px solid #000;
            padding: 8px;
            text-align: center;
          }
          h1 {
            text-align: center;
            margin-bottom: 8px;
            font-size: 24px;
          }
          h2 {
            text-align: center;
            margin-bottom: 8px;
            font-size: 16px;
          }
          </style>
        </head>
        <body>
          <h1>MSc Admission Result 2021-22</h1>
          <h2>Department of Computer Science and Engineering</h2>
          <table>
            <thead>
              <tr>
                <th>U_ID
                </th>
                <th>NAME
                </th>
                <th>Father
                </th>
                <th>Mother
                </th>
                <th>Roll
                </th>
                <th>Serial
                </th>
                <th>Marks
                </th>
                <th>Selected</th>
              </tr>
            </thead>
            <tbody>
              ${jsonData
                .map(
                  (item) => `
                <tr>
                  <td>${item.U_ID}</td>
                  <td>${item.A_NAME}</td>
                  <td>${item.F_NAME}</td>
                  <td>${item.M_NAME}</td>
                  <td>${item.roll}</td>
                  <td>${item.serial}</td>
                  <td>${item.marks}</td>
                  <td>${item.selected}</td>
                </tr>
              `
                )
                .join("")}
            </tbody>
          </table>
        </body>
      </html>
    `;
    return html;
  };

  const generatePDF = () => {
    const html = convertToHTML(data);

    // Generate PDF from the HTML template
    html2pdf().from(html).save("result.pdf");
  };

  const [verificationLoading, setVerificationLoading] = useState(false);

  // Verify Application
  const verifyApplication = (record) => {
    if (!verificationLoading) {
      // console.log("Verification");
      const dataToPost = new FormData();
      dataToPost.set("app_id", record["APP_ID"]);
      setVerificationLoading(true);

      api
        .post("/approveApplication.php", dataToPost, {
          headers: {
            Authorization: localStorage.getItem("jwt"),
          },
        })
        .then((response) => {
          toast.success(`Application Verified.`);
          toast.success(`SMS status: ${response.data["sms"]}`);
          setVerificationLoading(false);
          fetchData(paymentFilter, verifiedFilter);
        })
        .catch((err) => {
          toast.error("Failed to verify");
          setVerificationLoading(false);
          console.log(err);
        });
    }
  };

  const showDetails = (record) => {
    if (!detailsLoading) {
      setProfile({});

      setDetailsLoading(true);
      api
        .get(`/applications.php?id=${record["APP_ID"]}`, {
          headers: {
            Authorization: localStorage.getItem("jwt"),
          },
        })
        .then((response) => {
          setProfile(response.data.message);
          setDetailsLoading(false);
          setDetailsModalOpen(true); // Open Modal
        })
        .catch((err) => {
          setDetailsLoading(false);
          toast.error("Failed to load data");
          console.log(err);
        });
    }
  };

  // Edit Profile

  const [editAccessModalOpen, setEditAccessModalOpen] = useState(false);

  const confirmEditAccess = (record) => {
    setSelectedApp(record["APP_ID"]);
    setSelectedUID(record["U_ID"]);
    setEditAccessModalOpen(true);
  };

  const grantEditAccess = () => {
    let dataToPost = new FormData();
    dataToPost.set("app_id", selectedApp);
    dataToPost.set("u_id", selectedUID);

    api
      .post("/editGrant.php", dataToPost, {
        headers: {
          Authorization: localStorage.getItem("jwt"),
        },
      })
      .then((res) => {
        setSmsLoading(false);
        toast.success(`Access Granted`);
        fetchData(paymentFilter, verifiedFilter);
        setEditAccessModalOpen(false);
      })
      .catch((err) => {
        toast.error("Access grant failed");
        console.log(err);
        setEditAccessModalOpen(false);
      });
  };

  const revokeEditAccess = (record) => {
    let dataToPost = new FormData();
    dataToPost.set("app_id", record["APP_ID"]);
    dataToPost.set("u_id", record["U_ID"]);

    api
      .post("/editRevoke.php", dataToPost, {
        headers: {
          Authorization: localStorage.getItem("jwt"),
        },
      })
      .then((res) => {
        setSmsLoading(false);
        toast.success(`Access Revoked`);
        fetchData(paymentFilter, verifiedFilter);
      })
      .catch((err) => {
        toast.error("Access Revoke failed");
        console.log(err);
      });
  };

  const [editProfileModalOpen, setEditProfileModalOpen] = useState();
  const [loadingEditProfile, setLoadingEditProfile] = useState(false);

  const [editProfile_pic, setEditProfile_pic] = useState(null);
  const [editProfile_sig, setEditProfile_sig] = useState(null);
  const [editProfile_ssc, setEditProfile_ssc] = useState(null);
  const [editProfile_hsc, setEditProfile_hsc] = useState(null);
  const [editProfile_bsc, setEditProfile_bsc] = useState(null);
  const [editProfile_msc, setEditProfile_msc] = useState(null);
  const [editProfile_cv, setEditProfile_cv] = useState(null);

  const openEditProfile = (record) => {
    setSelectedUID(record["U_ID"]);
    setEditProfileModalOpen(true);
  };

  const editProfile = () => {
    if (!loadingEditProfile) {
      if (editProfile_pic == null) {
        toast.error("Select a picture.");
      } else if (editProfile_sig == null) {
        toast.error("Select signature.");
      } else if (editProfile_ssc == null) {
        toast.error("Select ssc transcript.");
      } else if (editProfile_hsc == null) {
        toast.error("Select hsc transcript.");
      } else if (editProfile_bsc == null) {
        toast.error("Select undergraduate transcript.");
      } else {
        // Submit the profile
        setLoadingEditProfile(true);
        const dataToPost = new FormData();
        dataToPost.set("a_pic", editProfile_pic);
        dataToPost.set("a_sig", editProfile_sig);
        dataToPost.set("ssc_transcript", editProfile_ssc);
        dataToPost.set("hsc_transcript", editProfile_hsc);
        dataToPost.set("ug_transcript", editProfile_bsc);
        dataToPost.set("ms_transcript", editProfile_msc);
        dataToPost.set("cv", editProfile_cv);
        dataToPost.set("u_id", selectedUID);
        api
          .post("/editProfile.php", dataToPost, {
            headers: {
              Authorization: localStorage.getItem("jwt"),
              Accept: "*/*",
            },
          })
          .then((res) => {
            toast.success("Successful");
            setLoadingEditProfile(false);
            setEditProfileModalOpen(false);
            fetchData(paymentFilter, verifiedFilter);
          })
          .catch((err) => {
            console.log(err);
            toast.error("Try again.");
            setLoadingEditProfile(false);
          });
      }
    }
  };
  // Communication
  const openSMSModal = (record) => {
    setSelectedApp(record["APP_ID"]);
    setSmsModalOpen(true);
  };

  const openEmailModal = (record) => {
    setSelectedApp(record["APP_ID"]);
    setEmailModalOpen(true);
  };

  function handleMessageChange(event) {
    setMessage(event.target.value);
  }

  const sendSMS = () => {
    if (!smsLoading) {
      setSmsLoading(true);
      let dataToPost = new FormData();
      dataToPost.set("app_id", selectedApp);
      dataToPost.set("message", message);
      api
        .post("/sms.php", dataToPost, {
          headers: {
            Authorization: localStorage.getItem("jwt"),
          },
        })
        .then((res) => {
          setSmsLoading(false);
          toast.success(`SMS sent: ${res.data["sms"]}`);
          setSmsModalOpen(false);
        })
        .catch((err) => {
          setSmsLoading(false);
          toast.error("SMS Failed");
          console.log(err);
        });
    }
  };

  const sendEmail = () => {
    if (!emailLoading) {
      setEmailLoading(true);
      let dataToPost = new FormData();
      dataToPost.set("app_id", selectedApp);
      dataToPost.set("message", message);
      api
        .post("/email.php", dataToPost, {
          headers: {
            Authorization: localStorage.getItem("jwt"),
          },
        })
        .then((res) => {
          setEmailLoading(false);
          toast.success(`Email sent: ${res.data["email"]}`);
          setEmailModalOpen(false);
        })
        .catch((err) => {
          setEmailLoading(false);
          setEmailModalOpen(false);
          toast.error("Email Failed");
          console.log(err);
        });
    }
  };

  const toPayment = (app_id) => {
    toast.error("Deadline Over. Applications are closed");
    // nav("/home");

    // let dataToPost = new FormData();
    // setApiLoading(true);
    // dataToPost.set("app_id", app_id);
    // api
    //   .post("/initiatePayment.php", dataToPost, {
    //     headers: {
    //       Authorization: localStorage.getItem("jwt"),
    //     },
    //   })
    //   .then((res) => {
    //     try {
    //       ReactGA.event("payment_initiated");
    //     } catch (gaerr) {
    //       console.log(gaerr);
    //     }

    //     setApiLoading(false);
    //     window.location.replace(`${res.data}`);
    //   })
    //   .catch((err) => {
    //     setApiLoading(false);
    //     console.log(err);
    //   });
  };

  // RESULT
  const [resultModalOpen, setResultModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [confettiShower, setConfettiShower] = useState(false);
  const openResultModal = (record) => {
    setResultModalOpen(true);
    setSelectedRecord(record);

    if (parseInt(record["selected"]) == 1) setConfettiShower(true);
  };

  const closeResultModal = () => {
    setResultModalOpen(false);
    setConfettiShower(false);
  };

  // BULK SMS / EMAIL

  const [selectedAppIds, setSelectedAppIds] = useState([]);
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      let appIdArr = [];
      for (let i = 0; i < selectedRows.length; i++) {
        appIdArr.push(selectedRows[i].APP_ID);
      }
      setSelectedAppIds(appIdArr);
    },
  };

  const bulkEmail = () => {
    let csAppId = "";
    for (let i = 0; i < selectedAppIds.length; i++) {
      csAppId += `${selectedAppIds[i]},`;
    }
    setSelectedApp(csAppId.slice(0, -1));
    setEmailModalOpen(true);
  };

  const bulkSMS = () => {
    let csAppId = "";
    for (let i = 0; i < selectedAppIds.length; i++) {
      csAppId += `${selectedAppIds[i]},`;
    }
    setSelectedApp(csAppId.slice(0, -1));
    setSmsModalOpen(true);
  };

  // Fetch Data
  const [resultPublished, setResultPublished] = useState(false);
  const fetchData = (paymentFilter, verifiedFilter) => {
    setLoading(true);
    api
      .get(
        `/applications.php?payment=${paymentFilter}&verified=${verifiedFilter}`,
        {
          headers: {
            Authorization: localStorage.getItem("jwt"),
          },
        }
      )
      .then((res) => {
        setData(res.data);
        searchData(searchTerm, res.data);
        if (res.data[0].hasOwnProperty("marks")) {
          setResultPublished(true);
        } else {
          setResultPublished(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Please apply first.");
        setLoading(false);
      });
  };

  // SEARCH DATA
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(data);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    searchData(e.target.value, data);
  };

  const kmpSubString = (a, b, pt) => {
    let j = 0,
      n = a.length,
      m = b.length;
    for (let i = 0; i < n; i++) {
      while (j > 0 && a[i] != b[j]) j = pt[j - 1];
      if (a[i] == b[j]) j++;
      if (j == m) return true;
    }
    return false;
  };

  const cmpSubSeq = (a, b) => {
    let j = 0,
      n = a.length,
      m = b.length;
    for (let i = 0; i < n; i++) {
      if (a[i] == b[j]) j++;
      if (j == m) return true;
    }
    return false;
  };

  const searchData = (searchKey, ori_data) => {
    if (searchKey !== "") {
      /* generate prefix table */
      // let n=searchKey.length;
      // let pt = new Array(n+5, 0);
      // for(let i=1;i<n;i++)
      // {
      //     let l=pt[i-1];
      //     while(l>0&&searchKey[i]!=searchKey[l]) l=pt[l-1];
      //     if(searchKey[l]==searchKey[i]) l++;
      //     pt[i]=l;
      // }

      const filteredData = [];
      for (let i = 0; i < data.length; i++) {
        if (
          cmpSubSeq(
            ori_data[i]["A_NAME"].toLowerCase(),
            searchKey.toLowerCase()
          )
        ) {
          filteredData.push(ori_data[i]);
        }

        if (ori_data[i].hasOwnProperty("roll")) {
          if (ori_data[i]["roll"] == searchKey.toString()) {
            filteredData.push(ori_data[i]);
          }
        }

        if (
          ori_data[i]["U_ID"]
            .toString()
            .toLowerCase()
            .startsWith(searchKey.toLowerCase())
        ) {
          filteredData.push(ori_data[i]);
        }
      }
      setFilteredData(filteredData);
    } else {
      setFilteredData(ori_data);
    }
  };

  useEffect(() => {
    if (user.role === "student" && user.profile === "0")
      nav("/profile", { state: "incomplete" });
    else {
      if (!isLoggedIn) nav("/login", { state: "redirected" });
      else if (user.verified === "0") nav("/verify");
      else {
        if (state === "applied") toast.success("Application Successful.");
        fetchData("", "");

        ReactGA.send({
          hitType: "pageview",
          page: window.location.pathname + window.location.search,
          title: "Submissions",
        });
      }
    }
  }, []);

  return (
    <div className="bg-white relative min-h-screen h-full dark:bg-gray-900 flex flex-col">
      <Navbar />
      <BottomNavbar />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
        theme="colored"
      />

      <div className="mt-6 lg:mt-16 mx-2 mb-16 lg:mx-10 relative">
        {/* Filter */}
        {user.role === "admin" || user.role === "supervisor" ? (
          <div>
            <div className="flex flex-row justify-between gap-5 mb-10">
              {/* Send SMS */}
              <div className="flex flex-row items-center justify-center">
                {selectedAppIds.length > 0 ? (
                  <div className="flex flex-row items-center justify-center">
                    <div className="flex flex-row justify-start mb-10 mt-8">
                      <button
                        onClick={bulkSMS}
                        className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white  focus:ring-4 focus:outline-none focus:ring-cyan-200 "
                      >
                        <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white  rounded-md group-hover:bg-opacity-0">
                          SMS
                        </span>
                      </button>
                    </div>

                    <div className="flex flex-row justify-start mb-10 mt-8">
                      <button
                        onClick={bulkEmail}
                        className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white  focus:ring-4 focus:outline-none focus:ring-cyan-200 "
                      >
                        <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white  rounded-md group-hover:bg-opacity-0">
                          Email
                        </span>
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="flex flex-row gap-5">
                {/* Search */}
                <div>
                  <label
                    htmlFor="search_btn"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Search
                  </label>
                  <input
                    id="search_btn"
                    value={searchTerm}
                    onChange={handleSearch}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Search by Name, Roll, ID"
                  ></input>
                </div>

                {/* Payment Status */}
                <div>
                  <label
                    htmlFor="payment_filter"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Payment status
                  </label>
                  <select
                    id="payment_filter"
                    value={paymentFilter}
                    onChange={handlePaymentFilterChange}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option value="">All</option>
                    <option value="0">Unpaid</option>
                    <option value="1">Paid</option>
                  </select>
                </div>

                {/* Verification Status */}
                <div>
                  <label
                    htmlFor="verification_filter"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Verification status
                  </label>
                  <select
                    id="verification_filter"
                    value={verifiedFilter}
                    onChange={handleVerifiedFilterChange}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option value="">All</option>
                    <option value="0">Pending</option>
                    <option value="1">Verified</option>
                  </select>
                </div>

                <button
                  type="button"
                  onClick={() => setResultDownloadModalOpen(true)}
                  className="lg:h-12 flex gap-x-2 justify-center items-center my-auto lg:mt-6 cursor-pointer text-md  bg-blue-500 px-3 py-1 text-white rounded-lg hover:scale-105 hover:bg-blue-600 transition-all duration-300"
                >
                  <FcDownload className="text-white" /> Download Result
                </button>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <Table
          loading={loading}
          dataSource={filteredData}
          rowKey="APP_ID"
          style={{ overflowX: "auto" }}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
        >
          {user.role === "admin" || user.role === "supervisor" ? (
            <Column
              title="User ID"
              dataIndex="U_ID"
              sorter={(a, b) => a.U_ID - b.U_ID}
            ></Column>
          ) : (
            ""
          )}
          {/* <Column
            title="Department"
            dataIndex="DEPT_NAME"
            render={(approved, record) => (
              <div>Computer Science & Engineering</div>
            )}
          ></Column> */}
          <Column
            title="Name"
            dataIndex="A_NAME"
            sorter={(a, b) => a.A_NAME.localeCompare(b.A_NAME)}
          ></Column>
          <Column
            title="Applied at"
            dataIndex="CREATED_ON"
            render={(approved, record) => (
              <div>{record.CREATED_ON.slice(0, 9)}</div>
            )}
          ></Column>

          <Column
            title="Verification"
            dataIndex="APP_VERIFIED"
            render={(approved, record) => (
              <div>
                {record.APP_VERIFIED === "0" ? (
                  <div className="flex gap-1 items-center">
                    <BiTimeFive />
                    <p>Pending</p>
                  </div>
                ) : (
                  <div className="flex gap-1 items-center">
                    <FcOk />
                    <p>Approved</p>
                  </div>
                )}
              </div>
            )}
          ></Column>

          <Column
            title="Payment"
            dataIndex="APP_PAYMENT"
            render={(payment, record) => (
              <div>
                {record.APP_VERIFIED == "0" ? (
                  <div className="flex gap-1 items-center">
                    <GiSandsOfTime />
                    <p>Verification Pending</p>
                  </div>
                ) : record.APP_PAYMENT === "1" ? (
                  <div className="flex gap-1 items-center">
                    <FcOk />
                    <p>Paid</p>
                  </div>
                ) : user.role === "admin" || user.role === "supervisor" ? (
                  <div className="flex gap-1 items-center">
                    <FcCancel />
                    <p>Unpaid</p>
                  </div>
                ) : (
                  <div className="">
                    <button
                      onClick={() => {
                        toPayment(record.APP_ID);
                      }}
                      disabled={apiLoading ? true : false}
                      className={`${
                        apiLoading ? "cursor-not-allowed" : ""
                      }text-white bg-blue-500 px-4 py-1 rounded-lg font-medium`}
                    >
                      <div className="flex justify-center">
                        {apiLoading === true ? (
                          <CgSpinner className="animate-spin h-5 w-5 self-center" />
                        ) : (
                          <p>Pay now</p>
                        )}
                      </div>
                    </button>
                  </div>
                )}
              </div>
            )}
          ></Column>

          {user.role === "student" ? (
            <div>
              <Column
                title="Admit"
                dataIndex="APP_PAYMENT"
                render={(payment, record) => (
                  <div>
                    {/* <div className="flex gap-1 items-center">
                      <BiTimeFive />
                      <p>Not yet published.</p>
                    </div> */}
                    {record.APP_PAYMENT == "1" && record.APP_VERIFIED == "1" ? (
                      <div
                        onClick={downloadAdmit}
                        className="flex gap-1 items-center cursor-pointer"
                      >
                        <FcDownload />
                        <p className="underline font-bold">{downloadText}</p>
                      </div>
                    ) : (
                      <div className="flex gap-1 items-center">
                        <BiTimeFive />
                        <p>Requirements not met</p>
                      </div>
                    )}
                  </div>
                )}
              ></Column>

              {resultPublished ? (
                <div>
                  <Column
                    title="Result"
                    dataIndex="U_ID"
                    render={(payment, record) => (
                      <button
                        onClick={() => openResultModal(record)}
                        className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white  focus:ring-4 focus:outline-none focus:ring-cyan-200 "
                      >
                        <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white  rounded-md group-hover:bg-opacity-0">
                          View
                        </span>
                      </button>
                    )}
                  ></Column>
                </div>
              ) : (
                ""
              )}

              <Column
                title="Action"
                dataIndex="id"
                render={(payment, record) => (
                  <div>
                    {record.EDIT_ACCESS ? (
                      <button
                        onClick={() => openEditProfile(record)}
                        className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white  focus:ring-4 focus:outline-none focus:ring-cyan-200 "
                      >
                        <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white  rounded-md group-hover:bg-opacity-0">
                          Edit Profile
                        </span>
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              ></Column>
            </div>
          ) : (
            ""
          )}

          {user.role === "admin" || user.role === "supervisor" ? (
            <div>
              {resultPublished ? (
                <div>
                  <Column
                    title="Roll"
                    dataIndex="roll"
                    sorter={(a, b) => a.roll - b.roll}
                  ></Column>

                  {/* Result */}
                  <Column
                    title="Result"
                    dataIndex="selected"
                    filters={[
                      { text: "Selected", value: 1 },
                      { text: "Withheld", value: 6 },
                      { text: "Waiting", value: 5 },
                      { text: "Failed", value: 0 },
                      { text: "Absent", value: 7 },
                    ]}
                    onFilter={(value, record) =>
                      record.selected && record.roll != 0
                        ? record.selected.indexOf(value) === 0
                        : ""
                    }
                    render={(id, record) => (
                      <div>
                        {parseInt(record.selected) == 1 ? (
                          <div className="bg-green-400 px-2 py-1 rounded-xl text-center text-white">
                            <p className="text-center">Selected</p>
                          </div>
                        ) : parseInt(record.selected) == 5 ? (
                          <div className="bg-blue-400 px-2 py-1 rounded-xl text-center text-white">
                            <p className="text-center">Waiting</p>
                          </div>
                        ) : parseInt(record.selected) == 6 ? (
                          <div className="bg-yellow-400 px-2 py-1 rounded-xl text-center text-white">
                            <p className="text-center">Withheld</p>
                          </div>
                        ) : parseInt(record.selected) == 7 ? (
                          <div className="bg-gray-900 px-2 py-1 rounded-xl text-center text-white">
                            <p className="text-center">Absent</p>
                          </div>
                        ) : parseInt(record.selected) == 0 ? (
                          <div className="bg-red-400 px-2 py-1 rounded-xl text-center text-white">
                            <p className="text-center">Failed</p>
                          </div>
                        ) : parseInt(record.selected) == 8 ? (
                          <div className="bg-gray-900 px-2 py-1 rounded-xl text-center text-white">
                            <p className="text-center">Cancelled</p>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                  ></Column>

                  {/* MCQ Mark */}
                  <Column
                    title="Mark"
                    dataIndex="marks"
                    // sorter={(a, b) => a.marks - b.marks}
                  ></Column>

                  {/* Exp Mark */}
                  <Column
                    title="Experience"
                    dataIndex="exp_mark"
                    // sorter={(a, b) => a.exp_mark - b.exp_mark}
                  ></Column>

                  {/* Total Mark */}
                  <Column
                    title="Total"
                    dataIndex="total"
                    // sorter={(a, b) => a.total - b.total}
                  ></Column>

                  {/* Merit */}
                  <Column
                    title="Merit"
                    dataIndex="merit_pos"
                    // sorter={(a, b) =>
                    //   parseInt(a.merit_pos) - parseInt(b.merit_pos)
                    // }
                  ></Column>
                </div>
              ) : (
                ""
              )}

              {/* Action */}
              <Column
                title="Action"
                dataIndex="id"
                render={(id, record) => (
                  <div>
                    <button
                      onClick={() => showDetails(record)}
                      className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white  focus:ring-4 focus:outline-none focus:ring-cyan-200 "
                    >
                      <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white  rounded-md group-hover:bg-opacity-0">
                        {detailsLoading ? "Loading" : "Details"}
                      </span>
                    </button>

                    {user.role == "admin" ? (
                      <>
                        <button
                          onClick={() => openSMSModal(record)}
                          className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white  focus:ring-4 focus:outline-none focus:ring-cyan-200 "
                        >
                          <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white  rounded-md group-hover:bg-opacity-0">
                            SMS
                          </span>
                        </button>

                        <button
                          onClick={() => openEmailModal(record)}
                          className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white  focus:ring-4 focus:outline-none focus:ring-cyan-200 "
                        >
                          <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white  rounded-md group-hover:bg-opacity-0">
                            Email
                          </span>
                        </button>

                        {record.APP_VERIFIED == "0" ? (
                          <button
                            onClick={() => verifyApplication(record)}
                            className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white  focus:ring-4 focus:outline-none focus:ring-cyan-200 "
                          >
                            <span className="flex  items-center  justify-between gap-2 relative px-5 py-2.5 transition-all ease-in duration-75 bg-white  rounded-md group-hover:bg-opacity-0">
                              {verificationLoading == true ? (
                                <div>Processing</div>
                              ) : (
                                <div className="flex gap-2 items-center">
                                  Verify <FcOk />
                                </div>
                              )}
                            </span>
                          </button>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              ></Column>

              {/* Edit Access - only Admin */}

              {user.role == "admin" ? (
                <Column
                  title="Edit Access"
                  dataIndex="id"
                  render={(id, record) => (
                    <div>
                      {record.EDIT_ACCESS ? (
                        <button
                          onClick={() => revokeEditAccess(record)}
                          className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white  focus:ring-4 focus:outline-none focus:ring-cyan-200 "
                        >
                          <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white  rounded-md group-hover:bg-opacity-0">
                            Revoke
                          </span>
                        </button>
                      ) : (
                        <button
                          onClick={() => confirmEditAccess(record)}
                          className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white  focus:ring-4 focus:outline-none focus:ring-cyan-200 "
                        >
                          <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white  rounded-md group-hover:bg-opacity-0">
                            Grant
                          </span>
                        </button>
                      )}
                    </div>
                  )}
                ></Column>
              ) : (
                <></>
              )}
            </div>
          ) : (
            ""
          )}
        </Table>

        {/* View Details Modal */}
        <Modal
          title="Details"
          centered
          open={detailsModalOpen}
          onOk={() => setDetailsModalOpen(false)}
          onCancel={() => setDetailsModalOpen(false)}
          okText="Close"
          cancelButtonProps={{ style: { display: "none" } }}
          width={1000}
          className="dark:bg-black"
        >
          <div className=" ">
            <div>
              <div className="text-black  mb-6 my-8 text-center text-xl">
                Personal Information
              </div>
              <div className="lg:flex lg:gap-x-10 lg:items-center">
                <div className="">
                  {profile.hasOwnProperty("A_PICPATH") ? (
                    <div
                      className="img-fluid picThumb mx-auto mb-6 lg:mb-0 w-1/3 lg:w-48"
                      style={{
                        backgroundImage: `url(${encodeURI(profile.A_PICPATH)})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="w-full">
                  <div className="relative z-0 w-full mb-6 group">
                    <input
                      type="text"
                      name="fullname"
                      id="fullname"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      required
                      disabled
                      value={profile.A_NAME}
                    />
                    <label
                      htmlFor="fullname"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                    >
                      Full Name
                    </label>
                  </div>
                  <div className="relative z-0 w-full mb-6 group">
                    <input
                      type="text"
                      name="fname"
                      id="fname"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      required
                      value={profile.F_NAME}
                      disabled={profile.F_NAME ? true : false}
                    />
                    <label
                      htmlFor="fname"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                    >
                      Father's Name
                    </label>
                  </div>
                  <div className="relative z-0 w-full mb-6 group">
                    <input
                      type="text"
                      name="mname"
                      id="mname"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      required
                      value={profile.M_NAME}
                      disabled={profile.M_NAME ? true : false}
                    />
                    <label
                      htmlFor="mname"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                    >
                      Mother's Name
                    </label>
                  </div>
                  <div className="relative z-0 w-full mb-6 group">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      required
                      value={profile.A_MAIL}
                      disabled={profile.A_MAIL ? true : false}
                    />
                    <label
                      htmlFor="email"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                    >
                      Email address
                    </label>
                  </div>
                  <div className="grid md:grid-cols-2 md:gap-6">
                    <div className="relative z-0 w-full mb-6 group">
                      <input
                        type="text"
                        name="dob"
                        id="dob"
                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder="DD/MM/YYYY"
                        required
                        value={profile.A_DOB}
                        disabled={profile.A_DOB ? true : false}
                      />
                      <label
                        htmlFor="dob"
                        className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-7"
                      >
                        Date of Birth
                      </label>
                    </div>
                    <div className="relative z-0 w-full mb-6 group">
                      <input
                        type="text"
                        name="phone"
                        id="phone"
                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder="Ex. 01234567890"
                        required
                        value={profile.A_PHONE}
                        disabled={profile.A_PHONE ? true : false}
                      />
                      <label
                        htmlFor="phone"
                        className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                      >
                        Phone
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              {/* Files */}
              <div className="w-full mt-8 mb-4">
                <div className="text-black dark:text-white mb-6 my-8 ml-5">
                  Uploaded files
                </div>
                <div
                  className={`grid grid-cols-1 ${
                    profile.CV_PATH && profile.MSC_TRANSCRIPT_PATH
                      ? "lg:grid-cols-7"
                      : "lg:grid-cols-5"
                  } ${
                    profile.MSC_TRANSCRIPT_PATH && !profile.CV_PATH
                      ? "lg:grid-cols-6"
                      : "lg:grid-cols-5"
                  } ${
                    profile.CV_PATH && !profile.MSC_TRANSCRIPT_PATH
                      ? "lg:grid-cols-6"
                      : "lg:grid-cols-5"
                  } gap-y-4 gap-x-20`}
                >
                  <a
                    className="hover:underline text-center bg-gray-100 border"
                    href={
                      /\s/g.test(profile.A_PICPATH)
                        ? profile.A_PICPATH.replace(/\s/g, "%20")
                        : profile.A_PICPATH
                    }
                    target="blank"
                  >
                    Picture
                  </a>
                  <a
                    className="hover:underline text-center bg-gray-100 border"
                    href={
                      /\s/g.test(profile.A_SIGPATH)
                        ? profile.A_SIGPATH.replace(/\s/g, "%20")
                        : profile.A_SIGPATH
                    }
                    target="blank"
                  >
                    Signature
                  </a>
                  <a
                    className="hover:underline text-center bg-gray-100 border"
                    href={
                      /\s/g.test(profile.SSC_TRANSCRIPT_PATH)
                        ? profile.SSC_TRANSCRIPT_PATH.replace(/\s/g, "%20")
                        : profile.SSC_TRANSCRIPT_PATH
                    }
                    target="blank"
                  >
                    SSC Transcript
                  </a>
                  <a
                    className="hover:underline text-center bg-gray-100 border"
                    href={
                      /\s/g.test(profile.HSC_TRANSCRIPT_PATH)
                        ? profile.HSC_TRANSCRIPT_PATH.replace(/\s/g, "%20")
                        : profile.HSC_TRANSCRIPT_PATH
                    }
                    target="blank"
                  >
                    HSC Transcript
                  </a>
                  <a
                    className="hover:underline text-center bg-gray-100 border"
                    href={
                      /\s/g.test(profile.UG_TRANSCRIPT_PATH)
                        ? profile.UG_TRANSCRIPT_PATH.replace(/\s/g, "%20")
                        : profile.UG_TRANSCRIPT_PATH
                    }
                    target="blank"
                  >
                    Undergraduate Transcript
                  </a>
                  {profile.MSC_TRANSCRIPT_PATH ? (
                    <a
                      className="hover:underline text-center bg-gray-100 border"
                      href={
                        /\s/g.test(profile.UG_TRANSCRIPT_PATH)
                          ? profile.MSC_TRANSCRIPT_PATH.replace(/\s/g, "%20")
                          : profile.MSC_TRANSCRIPT_PATH
                      }
                      target="blank"
                    >
                      Masters Transcript
                    </a>
                  ) : (
                    ""
                  )}
                  {profile.CV_PATH ? (
                    <a
                      className="hover:underline text-center bg-gray-100 border"
                      href={
                        /\s/g.test(profile.CV_PATH)
                          ? profile.CV_PATH.replace(/\s/g, "%20")
                          : profile.CV_PATH
                      }
                      target="blank"
                    >
                      CV
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              {/* Undergraduate */}

              <div>
                <div className="text-black  mb-6 my-8 ml-5">Undergraduate</div>
                <div className="md:grid md:grid-cols-3 md:gap-5">
                  <div className="relative z-0 w-full mb-6 group">
                    <input
                      type="text"
                      name="ug_type"
                      id="ug_type"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder="Ex. BSc or Bachelor of Science"
                      required
                      value={profile.UG_TYPE}
                      disabled={profile.UG_TYPE ? true : false}
                    />
                    <label
                      htmlFor="ug_type"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                    >
                      Graduation Type
                    </label>
                  </div>
                  <div
                    className={`relative ${
                      !profile.UG_REG && !profile.UG_UNI ? "md:col-span-2" : ""
                    } z-0 w-full mb-6 group`}
                  >
                    <input
                      type="text"
                      name="ug_institution"
                      id="ug_institution"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      required
                      value={uni_name_from_id(profile.UG_INSTITUTION)}
                      disabled={profile.UG_INSTITUTION ? true : false}
                    />
                    <label
                      htmlFor="ug_institution"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                    >
                      Institution
                    </label>
                  </div>
                  <div
                    className={`relative ${
                      profile.UG_REG ? "block" : "hidden"
                    } z-0 w-full mb-6 group`}
                  >
                    <input
                      type="text"
                      name="ug_reg"
                      id="ug_reg"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      value={profile.UG_REG}
                      disabled={profile.UG_REG ? true : false}
                      required
                    />
                    <label
                      htmlFor="ug_reg"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                    >
                      DU Registration Number
                    </label>
                  </div>
                  <div
                    className={`relative ${
                      profile.UG_UNI ? "block" : "hidden"
                    } z-0 w-full mb-6 group`}
                  >
                    <input
                      type="text"
                      name="ug_uni"
                      id="ug_uni"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      value={profile.UG_UNI}
                      disabled={profile.UG_UNI ? true : false}
                      required
                    />
                    <label
                      htmlFor="ug_uni"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                    >
                      Institution Name
                    </label>
                  </div>
                </div>
                <div
                  className={`md:grid ${
                    profile.UG_SUB ? "md:grid-cols-4" : "md:grid-cols-3"
                  }  md:gap-5`}
                >
                  <div className="relative z-0 w-full mb-6 group">
                    <input
                      type="text"
                      name="ug_subject"
                      id="ug_subject"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      required
                      value={sub_name_from_id(profile.UG_SUBJECT)}
                      disabled={profile.UG_SUBJECT ? true : false}
                    />
                    <label
                      htmlFor="ug_subject"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                    >
                      Subject
                    </label>
                  </div>
                  <div
                    className={`relative ${
                      profile.UG_SUB ? "block" : "hidden"
                    } z-0 w-full mb-6 group`}
                  >
                    <input
                      type="text"
                      name="ug_sub"
                      id="ug_sub"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder="Ex. Computer Science and Engineering"
                      value={profile.UG_SUB}
                      disabled={profile.UG_SUB ? true : false}
                      required
                    />
                    <label
                      htmlFor="ug_sub"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                    >
                      Subject Name
                    </label>
                  </div>
                  <div className="relative z-0 w-full mb-6 group">
                    <input
                      type="text"
                      name="ug_pass_year"
                      id="ug_pass_year"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder="Ex. 2022"
                      required
                      value={profile.UG_PASS_YEAR}
                      disabled={profile.UG_PASS_YEAR ? true : false}
                    />
                    <label
                      htmlFor="ug_pass_year"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                    >
                      Passing Year
                    </label>
                  </div>
                  <div className="relative z-0 w-full mb-6 group">
                    <input
                      type="text"
                      name="ug_cgpa"
                      id="ug_cgpa"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      required
                      value={profile.UG_CGPA}
                      disabled={profile.UG_CGPA ? true : false}
                    />
                    <label
                      htmlFor="ug_cgpa"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                    >
                      CGPA (Out of 4.00)
                    </label>
                  </div>
                </div>
              </div>

              {/* Masters */}
              {profile.MSC_CGPA ? (
                <div>
                  <div className="text-black dark:text-white mb-6 my-8 ml-5">
                    MSc
                  </div>
                  <div className="md:grid md:grid-cols-4 md:gap-5">
                    <div
                      className={`relative ${
                        !profile.MSC_UNI ? "md:col-span-4" : ""
                      } z-0 w-full mb-6 group`}
                    >
                      <input
                        type="text"
                        name="msc_institution"
                        id="msc_institution"
                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                        required
                        defaultValue={uni_name_from_id(profile.MSC_INSTITUTION)}
                        disabled={profile.MSC_INSTITUTION ? true : false}
                      />
                      <label
                        htmlFor="msc_institution"
                        className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                      >
                        Institution
                      </label>
                    </div>
                    <div
                      className={`relative ${
                        profile.MSC_UNI ? "block col-span-3" : "hidden"
                      } z-0 w-full mb-6 group`}
                    >
                      <input
                        type="text"
                        name="msc_uni"
                        id="msc_uni"
                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                        defaultValue={profile.MSC_UNI}
                        disabled={profile.MSC_UNI ? true : false}
                        required
                      />
                      <label
                        htmlFor="msc_uni"
                        className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                      >
                        Institution Name
                      </label>
                    </div>
                  </div>
                  <div className={`md:grid md:grid-cols-3 md:gap-5`}>
                    <div className="relative z-0 w-full mb-6 group">
                      <input
                        type="text"
                        name="msc_subject"
                        id="msc_subject"
                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                        required
                        defaultValue={profile.MSC_SUB}
                        disabled={profile.MSC_SUB ? true : false}
                      />
                      <label
                        htmlFor="msc_subject"
                        className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                      >
                        Topic
                      </label>
                    </div>
                    <div className="relative z-0 w-full mb-6 group">
                      <input
                        type="text"
                        name="msc_pass_year"
                        id="msc_pass_year"
                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=""
                        required
                        defaultValue={profile.MSC_PASS_YEAR}
                        disabled={profile.MSC_PASS_YEAR ? true : false}
                      />
                      <label
                        htmlFor="msc_pass_year"
                        className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                      >
                        Passing Year
                      </label>
                    </div>
                    <div className="relative z-0 w-full mb-6 group">
                      <input
                        type="text"
                        name="msc_cgpa"
                        id="msc_cgpa"
                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                        required
                        defaultValue={profile.MSC_CGPA}
                        disabled={profile.MSC_CGPA ? true : false}
                      />
                      <label
                        htmlFor="msc_cgpa"
                        className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                      >
                        CGPA (Out of 4.00)
                      </label>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="text-black  mb-6 my-8 text-center text-xl">
                Educational Information
              </div>

              {/* SSC */}

              <div>
                <div className="text-black  mb-6 my-8 ml-5">
                  SSC / Equivalent
                </div>
                <div className="md:grid md:grid-cols-2 md:gap-5">
                  <div className="relative z-0 w-full mb-6 group">
                    <input
                      type="text"
                      name="ssc_roll"
                      id="ssc_roll"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      required
                      value={profile.SSC_ROLL}
                      disabled={profile.SSC_ROLL ? true : false}
                    />
                    <label
                      htmlFor="ssc_roll"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                    >
                      Roll No.
                    </label>
                  </div>
                  <div className="relative z-0 w-full mb-6 group">
                    <input
                      type="text"
                      name="ssc_board1"
                      id="ssc_board1"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      required
                      value={
                        profile.SSC_BOARD === "10"
                          ? "Dhaka"
                          : profile.SSC_BOARD === "11"
                          ? "Cumilla"
                          : profile.SSC_BOARD === "12"
                          ? "Rajshahi"
                          : profile.SSC_BOARD === "13"
                          ? "Jashore"
                          : profile.SSC_BOARD === "14"
                          ? "Chattogram"
                          : profile.SSC_BOARD === "15"
                          ? "Barishal"
                          : profile.SSC_BOARD === "16"
                          ? "Sylhet"
                          : profile.SSC_BOARD === "17"
                          ? "Dinajpur"
                          : profile.SSC_BOARD === "18"
                          ? "Madrasah"
                          : profile.SSC_BOARD === "19"
                          ? "Mymensingh"
                          : profile.SSC_BOARD === "52"
                          ? "Vocational"
                          : profile.SSC_BOARD === "53"
                          ? "Business Managemen"
                          : profile.SSC_BOARD === "54"
                          ? "Diploma In Commerce"
                          : profile.SSC_BOARD === "60"
                          ? "GCE or Others"
                          : profile.SSC_BOARD
                      }
                      disabled={profile.SSC_BOARD ? true : false}
                    />
                    <label
                      htmlFor="ssc_board1"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                    >
                      Board
                    </label>
                  </div>
                </div>
                <div className="md:grid md:grid-cols-2 md:gap-5">
                  <div className="relative z-0 w-full mb-6 group">
                    <input
                      type="text"
                      name="ssc_year1"
                      id="ssc_year1"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      required
                      value={
                        parseInt(profile.SSC_YEAR) < 23
                          ? parseInt(profile.SSC_YEAR) + 2000
                          : parseInt(profile.SSC_YEAR)
                      }
                      disabled={profile.SSC_YEAR ? true : false}
                    />
                    <label
                      htmlFor="ssc_year1"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                    >
                      Passing Year
                    </label>
                  </div>
                  <div className="relative z-0 w-full mb-6 group">
                    <input
                      type="text"
                      name="ssc_result"
                      id="ssc_result"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      required
                      value={profile.SSC_RESULT}
                      disabled={profile.SSC_RESULT ? true : false}
                    />
                    <label
                      htmlFor="ssc_result"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                    >
                      GPA (Out of 5.00)
                    </label>
                  </div>
                </div>
              </div>

              {/* HSC */}

              <div>
                <div className="text-black  mb-6 my-8 ml-5">
                  HSC / Equivalent
                </div>
                <div className="md:grid md:grid-cols-2 md:gap-5">
                  <div className="relative z-0 w-full mb-6 group">
                    <input
                      type="text"
                      name="hsc_roll"
                      id="hsc_roll"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      required
                      value={profile.HSC_ROLL}
                      disabled={profile.HSC_ROLL ? true : false}
                    />
                    <label
                      htmlFor="hsc_roll"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                    >
                      Roll No.
                    </label>
                  </div>
                  <div className="relative z-0 w-full mb-6 group">
                    <input
                      type="text"
                      name="hsc_board"
                      id="hsc_board"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      required
                      value={
                        profile.HSC_BOARD === "10"
                          ? "Dhaka"
                          : profile.HSC_BOARD === "11"
                          ? "Cumilla"
                          : profile.HSC_BOARD === "12"
                          ? "Rajshahi"
                          : profile.HSC_BOARD === "13"
                          ? "Jashore"
                          : profile.HSC_BOARD === "14"
                          ? "Chattogram"
                          : profile.HSC_BOARD === "15"
                          ? "Barishal"
                          : profile.HSC_BOARD === "16"
                          ? "Sylhet"
                          : profile.HSC_BOARD === "17"
                          ? "Dinajpur"
                          : profile.HSC_BOARD === "18"
                          ? "Madrasah"
                          : profile.HSC_BOARD === "19"
                          ? "Mymensingh"
                          : profile.HSC_BOARD === "52"
                          ? "Vocational"
                          : profile.HSC_BOARD === "53"
                          ? "Business Managemen"
                          : profile.HSC_BOARD === "54"
                          ? "Diploma In Commerce"
                          : profile.HSC_BOARD === "60"
                          ? "GCE or Others"
                          : profile.HSC_BOARD
                      }
                      disabled={profile.HSC_BOARD ? true : false}
                    />
                    <label
                      htmlFor="hsc_board"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                    >
                      Board
                    </label>
                  </div>
                </div>
                <div className="md:grid md:grid-cols-2 md:gap-5">
                  <div className="relative z-0 w-full mb-6 group">
                    <input
                      type="text"
                      name="hsc_year"
                      id="hsc_year"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      required
                      value={
                        parseInt(profile.HSC_YEAR) < 23
                          ? parseInt(profile.HSC_YEAR) + 2000
                          : parseInt(profile.HSC_YEAR)
                      }
                      disabled={profile.HSC_YEAR ? true : false}
                    />
                    <label
                      htmlFor="hsc_year"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                    >
                      Passing Year
                    </label>
                  </div>
                  <div className="relative z-0 w-full mb-6 group">
                    <input
                      type="text"
                      name="hsc_result"
                      id="hsc_result"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      required
                      value={profile.HSC_RESULT}
                      disabled={profile.HSC_RESULT ? true : false}
                    />
                    <label
                      htmlFor="hsc_result"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                    >
                      GPA (Out of 5.00)
                    </label>
                  </div>
                </div>
              </div>

              {/* JOB */}
              {profile.JOB_COMPANY ? (
                <div>
                  <div className="text-black dark:text-white mb-6 my-8 ml-5">
                    Work Experience
                  </div>
                  <div className={`md:grid md:grid-cols-3 md:gap-5`}>
                    <div className="relative z-0 w-full mb-6 group">
                      <input
                        type="text"
                        name="job_company"
                        id="job_company"
                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                        required
                        defaultValue={profile.JOB_COMPANY}
                        disabled={profile.JOB_COMPANY ? true : false}
                      />
                      <label
                        htmlFor="job_company"
                        className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                      >
                        Company
                      </label>
                    </div>
                    <div className="relative z-0 w-full mb-6 group">
                      <input
                        type="text"
                        name="job_position"
                        id="job_position"
                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=""
                        required
                        defaultValue={profile.JOB_POSITION}
                        disabled={profile.JOB_POSITION ? true : false}
                      />
                      <label
                        htmlFor="job_position"
                        className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                      >
                        Position
                      </label>
                    </div>
                    <div className="relative z-0 w-full mb-6 group">
                      <input
                        type="text"
                        name="job_experience"
                        id="job_experience"
                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                        required
                        defaultValue={profile.JOB_EXPIRENNCE}
                        disabled={profile.JOB_EXPIRENNCE ? true : false}
                      />
                      <label
                        htmlFor="job_experience"
                        className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                      >
                        Years of experience
                      </label>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </Modal>

        {/* Send SMS Modal */}
        <Modal
          title="Send SMS"
          centered
          open={smsModalOpen}
          onOk={() => sendSMS()}
          onCancel={() => setSmsModalOpen(false)}
          okText={smsLoading ? "Sending" : "Send"}
        >
          <div>
            <label
              htmlFor="message"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Your message
            </label>
            <textarea
              id="message"
              rows="4"
              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Write message here..."
              onChange={handleMessageChange}
              value={message}
            ></textarea>
          </div>
        </Modal>

        {/* Send Email Modal */}
        <Modal
          title="Send Email"
          centered
          open={emailModalOpen}
          onOk={() => sendEmail()}
          onCancel={() => setEmailModalOpen(false)}
          okText={emailLoading ? "Sending" : "Send"}
        >
          <div>
            <label
              htmlFor="message"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Your message
            </label>
            <textarea
              id="message"
              rows="4"
              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Write message here..."
              onChange={handleMessageChange}
              value={message}
            ></textarea>
          </div>
        </Modal>

        {/* Edit Profile Modal */}
        <Modal
          title="Edit Profile"
          centered
          open={editProfileModalOpen}
          onOk={() => editProfile()}
          onCancel={() => setEditProfileModalOpen(false)}
          okText={loadingEditProfile ? "Submitting" : "Submit"}
          width={1000}
          className="dark:bg-black"
        >
          <div>
            <div className="relative z-0 w-full my-6 group">
              <div className="text-md">
                <span className="font-bold"> Picture</span> (named: photo.jpg,
                size: under 1MB)
              </div>
              <input
                type="file"
                name="a_pic"
                id="a_pic"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
                onChange={(e) => setEditProfile_pic(e.target.files[0])}
              />
            </div>

            <div className="relative z-0 w-full my-6 group">
              <div className="text-md">
                <span className="font-bold"> Signature </span> (named:
                signature.jpg, size: under 1MB)
              </div>
              <input
                type="file"
                name="a_sig"
                id="a_sig"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
                onChange={(e) => setEditProfile_sig(e.target.files[0])}
              />
            </div>

            <div className="relative z-0 w-full my-6 group">
              <div className="text-md">
                <span className="font-bold"> SSC Transcript</span> (named:
                ssc.jpg/ssc.pdf, size: under 1-2MB)
              </div>
              <input
                type="file"
                name="ssc_transcript"
                id="ssc_transcript"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
                onChange={(e) => setEditProfile_ssc(e.target.files[0])}
              />
            </div>

            <div className="relative z-0 w-full my-6 group">
              <div className="text-md">
                <span className="font-bold"> HSC Transcript</span> (named:
                hsc.jpg/hsc.pdf, size: under 1-2MB)
              </div>
              <input
                type="file"
                name="hsc_transcript"
                id="hsc_transcript"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
                onChange={(e) => setEditProfile_hsc(e.target.files[0])}
              />
            </div>

            <div className="relative z-0 w-full my-6 group">
              <div className="text-md">
                <span className="font-bold">Undergraduate Transcript</span>
                (named: ug.jpg/ug.pdf, size: under 1-3MB)
              </div>
              <input
                type="file"
                name="ug_transcript"
                id="ug_transcript"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
                onChange={(e) => setEditProfile_bsc(e.target.files[0])}
              />
            </div>

            {/* Optional */}
            <div className="relative z-0 w-full my-6 group">
              <div className="text-md">
                <span className="font-bold">MSc Transcript</span>
                (named: msc.jpg/msc.pdf, size: under 1-3MB)
              </div>
              <input
                type="file"
                name="msc_transcript"
                id="msc_transcript"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                onChange={(e) => setEditProfile_msc(e.target.files[0])}
              />
            </div>
            <div className="relative z-0 w-full my-6 group">
              <div className="text-md">
                <span className="font-bold"> CV</span> (named: cv.jpg/cv.pdf,
                size: under 1-3MB)
              </div>
              <input
                type="file"
                name="cv"
                id="cv"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                onChange={(e) => setEditProfile_cv(e.target.files[0])}
              />
            </div>
          </div>
        </Modal>

        {/* Grant Access Confirm */}
        <Modal
          title="Grant Access"
          centered
          open={editAccessModalOpen}
          onOk={() => grantEditAccess()}
          onCancel={() => setEditAccessModalOpen(false)}
          okText={loadingEditProfile ? "Submitting" : "Submit"}
          width={1000}
          className="dark:bg-black"
        >
          <div>
            Are your sure want to grant edit access to this applicant?
            <br />
            Applicant will receive both SMS & EMAIL to reupload files with
            instructions.
          </div>
        </Modal>

        {/* Result Modal */}
        <Modal
          title=""
          centered
          open={resultModalOpen}
          onOk={closeResultModal}
          onCancel={closeResultModal}
          width={1000}
          className="dark:bg-black"
        >
          {/* Status
          0 | Failed
          1 | Passed

          5 | Waiting
          6 | Withheld
          7 | Absent 
          8 | Cancelled
          */}

          <div>
            {parseInt(selectedRecord["selected"]) == 1 ? (
              <div>
                <div className="flex flex-col justify-center items-center">
                  <div className="text-2xl md:text-5xl font-black text-green-500">
                    Congratulations!
                  </div>
                  <div className="mt-4 text-xl">
                    <u>You have been selected</u> for the PMICS Admisssion.
                  </div>
                  <div className="mt-4 text-xl font-bold">
                    Roll: {selectedRecord["roll"]} <br />
                    MCQ Mark: {selectedRecord["marks"]} <br />
                    Experience Point: {selectedRecord["exp_mark"]}
                    <br />
                    Total Marks: {selectedRecord["total"]}
                    <br />
                    Merit Position: {selectedRecord["merit_pos"]}
                  </div>
                  <div className="mt-4 text-lg">
                    Please regularly monitor the website homepage for any
                    upcoming announcements or updates regarding admission.
                  </div>
                </div>
              </div>
            ) : parseInt(selectedRecord["selected"]) == 5 ? (
              <div>
                <div className="flex flex-col justify-center items-center">
                  <div className="text-2xl md:text-5xl font-black text-green-500"></div>
                  <div className="mt-4 text-xl">
                    You are on the <u> waiting list</u>. for the PMICS
                    Admisssion.
                  </div>
                  <div className="mt-4 text-xl font-bold">
                    Roll: {selectedRecord["roll"]} <br />
                    MCQ Mark: {selectedRecord["marks"]} <br />
                    Experience Point: {selectedRecord["exp_mark"]}
                    <br />
                    Total Marks: {selectedRecord["total"]}
                    <br />
                    Merit Position: {selectedRecord["merit_pos"]}
                  </div>
                  <div className="mt-4 text-lg">
                    Please regularly monitor the website homepage for any
                    upcoming announcements or updates regarding admission.
                  </div>
                </div>
              </div>
            ) : parseInt(selectedRecord["selected"]) == 6 ? (
              <div>
                <div className="flex flex-col justify-center items-center">
                  <div className="text-2xl md:text-5xl font-black text-green-500"></div>
                  <div className="mt-4 text-xl">
                    Your application is <u> withheld</u> because of shortage of
                    required documents. <br />
                    Please contact the office within 4:00 PM, 24th July, 2023.
                  </div>
                  <div className="mt-4 text-xl font-bold">
                    Roll: {selectedRecord["roll"]} <br />
                    MCQ Mark: {selectedRecord["marks"]} <br />
                    Experience Point: {selectedRecord["exp_mark"]}
                    <br />
                    Total Marks: {selectedRecord["total"]}
                    <br />
                    Merit Position: {selectedRecord["merit_pos"]}
                  </div>
                </div>
              </div>
            ) : parseInt(selectedRecord["selected"]) == 7 ? (
              <div>
                <div className="flex flex-col justify-center items-center">
                  <div className="text-2xl md:text-5xl  font-black">Sorry</div>
                  <div className="mt-4 text-xl">
                    You were <u> absent</u> in the admission test.
                  </div>
                  <div className="mt-4 text-xl font-bold">
                    Roll: {selectedRecord["roll"]}
                  </div>
                </div>
              </div>
            ) : parseInt(selectedRecord["selected"]) == 0 ? (
              <div>
                <div className="flex flex-col justify-center items-center">
                  <div className="text-2xl md:text-5xl  font-black">Sorry</div>
                  <div className="mt-4 text-xl">
                    We deeply appreciate your application but unfortunately, you
                    were <u> not selected</u> .
                  </div>
                  <div className="mt-4 text-xl font-bold">
                    Roll: {selectedRecord["roll"]} <br />
                    MCQ Score: {selectedRecord["marks"]}
                  </div>
                </div>
              </div>
            ) : parseInt(selectedRecord["selected"]) == 8 ? (
              <div>
                <div className="flex flex-col justify-center items-center">
                  <div className="text-2xl md:text-5xl  font-black">Sorry</div>
                  <div className="mt-4 text-xl">
                    Your admission opportunity to PMICS program of CSE DU has
                    been <u>cancelled</u>.
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </Modal>

        {/* Result Download Modal */}
        <Modal
          title="Download Result"
          centered
          open={resultDownloadModalOpen}
          onOk={() => setResultDownloadModalOpen(false)}
          onCancel={() => setResultDownloadModalOpen(false)}
          className="dark:bg-black"
        >
          <div className="flex flex-col justify-between gap-y-4 mx-4 my-6">
            <button
              type="button"
              onClick={downloadXLSX}
              className="bg-green-400 flex justify-center items-center gap-x-4 p-2 text-lg lg:text-xl rounded-xl text-white"
            >
              <SiMicrosoftexcel /> Excel
            </button>
            <button
              type="button"
              onClick={generatePDF}
              className="bg-blue-500 flex justify-center items-center gap-x-4 p-2 text-lg lg:text-xl rounded-xl text-white"
            >
              <VscFilePdf /> PDF
            </button>
          </div>
        </Modal>

        {/* Confetti */}
        {confettiShower ? (
          <div>
            <div className=" fixed top-20 left-0">
              <Confetti width={window.innerWidth} height={window.innerHeight} />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Submission;
