import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import api from "../api";
import "../index.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Spinner from "../components/Spinner";
import BottomNavbar from "../components/BottomNavbar";
import { useSearchParams } from "react-router-dom";
import { useGlobalState } from "../components/UserContext";
import { Select } from "antd";

const Students = () => {
  const [loading, setLoading] = useState(true);
  const [searchParam, setSearchParam] = useSearchParams();
  const [students, setStudents] = useState([]);
  const [user, setUser] = useGlobalState("user");
  const [isLoggedIn, setIsLoggedIn] = useGlobalState("isLoggedIn");
  const getStudents = async () => {
    setLoading(true);
    const batch = searchParam.get("batch");
    api
      .get(`/studentList.php?batch=${batch}`)
      .then((res) => {
        // console.log(res.data);
        setStudents(res.data);
        setLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
        toast.error("Error fetching students");
      });
  };

  useEffect(() => {
    getStudents();
  }, []);

  if (loading)
    return (
      <div>
        <Spinner />
      </div>
    );
  return (
    <div className="bg-white relative min-h-screen h-full dark:bg-gray-900 flex flex-col items-center justify-center">
      <Navbar />
      <BottomNavbar />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
        theme="colored"
      />

      <div className="mt-6 lg:mt-16 mb-16 lg:mx-20 relative mx-auto flex flex-col items-center">
        <p className="text-2xl font-bold">
          Batch {searchParam.get("batch")} Students
        </p>

        {user.role === "admin" ? (
          <>
            <div className="mt-8 flex flex-row flex-wrap gap-4 mx-auto items-center justify-evenly">
              {/* Map over students */}
              {students.map((student) => (
                <div class="flex flex-col items-center justify-evenly w-72 bg-white border border-gray-200 rounded-lg dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                  <img
                    class="object-cover rounded-t-lg  w-1/2 h-48 md:w-36 md:h-48 md:rounded-none md:rounded-l-lg mt-2"
                    src={student.pic}
                    alt=""
                  />
                  <div class="flex flex-col justify-between p-4 leading-normal">
                    <h5 class="mb-2 text-lg font-medium tracking-tight text-gray-900 dark:text-white">
                      {student.name}
                    </h5>

                    <div class="flex items-center justify-center w-full">
                      <Select
                        defaultValue={student.selection_status}
                        options={[
                          { label: "Selected", value: "1" },
                          { label: "Failed", value: "0" },
                          { label: "Waiting", value: "5" },
                          { label: "Withheld", value: "6" },
                          { label: "Absent", value: "7" },
                          { label: "Cancelled", value: "8" },
                        ]}
                        onChange={(value) => {
                          const dataToPost = new FormData();
                          dataToPost.append("app_id", student.app_id);
                          dataToPost.append("status", value);

                          api
                            .post("/studentList.php", dataToPost, {
                              headers: {
                                Authorization: localStorage.getItem("jwt"),
                              },
                            })
                            .then((res) => {
                              toast.success("Selection status updated");
                              getStudents();
                            })
                            .catch((err) => {
                              toast.error("Error updating selection status");
                            });
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            <div className="mt-8 flex flex-row flex-wrap gap-4 mx-auto items-center justify-evenly">
              {/* Map over students */}
              {students.map((student) =>
                student.selection_status === "1" ? (
                  <div class="flex flex-col items-center justify-evenly w-72 bg-white border border-gray-200 rounded-lg dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                    <img
                      class="object-cover rounded-t-lg  w-1/2 h-48 md:w-36 md:h-48 md:rounded-none md:rounded-l-lg mt-2"
                      src={student.pic}
                      alt=""
                    />
                    <div class="flex flex-col justify-between p-4 leading-normal">
                      <h5 class="mb-2 text-lg font-medium tracking-tight text-gray-900 dark:text-white">
                        {student.name}
                      </h5>
                    </div>
                  </div>
                ) : (
                  <></>
                )
              )}
            </div>{" "}
          </>
        )}
      </div>
    </div>
  );
};

export default Students;
