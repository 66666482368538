/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import api from "../api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useGlobalState } from "../components/UserContext";
import BottomNavbar from "../components/BottomNavbar";
import { MdOutlineMailOutline } from "react-icons/md";
import { BiChevronRight } from "react-icons/bi";

const People = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useGlobalState("user");
  const [isLoggedIn, setIsLoggedIn] = useGlobalState("isLoggedIn");
  const [jwt, setJwt] = useGlobalState("jwt");

  const [commitee, setCommitee] = useState([]);
  const [instructors, setInstructors] = useState([]);
  const [co_instrucors, setCo_instrucors] = useState([]);
  const [tas, setTas] = useState([]);

  const [dataLoading, setDataLoading] = useState(true);

  const getData = () => {
    setDataLoading(true);
    api.get("/committee.php").then((response) => {
      let sorted_instructor = response.data.instructors;
      sorted_instructor.sort((a, b) => a.SERIAL - b.SERIAL);
      setInstructors(sorted_instructor);

      let sorted_co_ins = response.data.co_instructors;
      sorted_co_ins.sort((a, b) => a.SERIAL - b.SERIAL);
      setCo_instrucors(sorted_co_ins);

      let sorted_tas = response.data.ta;
      sorted_tas.sort((a, b) => a.SERIAL - b.SERIAL);
      setTas(sorted_tas);

      let sorted_committee = response.data.coordinator;
      sorted_committee.sort((a, b) => a.SERIAL - b.SERIAL);
      setCommitee(response.data.coordinator);

      setDataLoading(false);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return dataLoading ? (
    <div></div>
  ) : (
    <div className="bg-white min-h-screen h-full dark:bg-gray-900 flex flex-col">
      <Navbar />
      <BottomNavbar />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
        theme="colored"
      />
      <div
        className="mt-6 lg:mt-16 mb-16 mx-10 relative overflow-x-auto"
        id="instructors"
      >
        {/* Instructor Panel */}
        <div className="mb-16 w-3/4 mx-auto">
          <div className="text-xl mb-16 md:text-3xl text-center dark-text-white ">
            Instructors
          </div>
          <div className=" flex flex-row items-center justify-evenly flex-wrap">
            {instructors.map((instructor, i) => {
              return (
                <div
                  key={i}
                  className="rounded-sm shadow-md pb-4 w-80 mx-4 mt-8 h-full px-2"
                >
                  <div
                    style={{
                      background: `url(${instructor.PHOTO})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      height: "300px",
                    }}
                  ></div>
                  {/* <img
                      src={instructor.photo}
                      alt={instructor.name}
                      className="img-fluid mx-auto w-3/4 h-3/4"
                    /> */}
                  <div className="text-center font-medium text-sm lg:text-xl mt-4 h-12">
                    {instructor.NAME}
                  </div>

                  <div className="text-center text-neutral-500 mt-2">
                    {instructor.DESIGNATION}
                  </div>

                  <div className="text-center text-neutral-500 my-2">
                    {instructor.INSTITUTION}
                  </div>

                  <div className=" text-center flex flex-row items-center justify-center">
                    <MdOutlineMailOutline className="mr-2" /> {instructor.EMAIL}
                  </div>
                  <div className="text-center text-neutral-500 mt-2">
                    {instructor.SUBJECT}
                  </div>

                  <a
                    href={instructor.PROFILE}
                    target="blank"
                    className="text-center cursor-pointer bg-blue-500 hover:bg-blue-600 text-white w-3/4 mx-auto rounded-md shadow-sm py-1 my-2 flex items-center justify-center"
                  >
                    View Profile
                    <BiChevronRight className="text-white text-2xl" />
                  </a>
                </div>
              );
            })}
          </div>
        </div>

        {/* Co-instructor */}
        <div className="mb-16 w-3/4 mx-auto" id="tas">
          <div className="text-xl mb-16 md:text-3xl text-center dark-text-white ">
            Co-Instructors
          </div>
          <div className=" flex flex-row items-center justify-evenly flex-wrap">
            {co_instrucors.map((co_instrucor, i) => {
              return (
                <div
                  key={i}
                  className="rounded-sm shadow-md pb-4 w-80 mx-4 mt-8 h-full px-2"
                >
                  <div
                    style={{
                      background: `url(${co_instrucor.PHOTO})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      height: "300px",
                    }}
                  ></div>
                  <div className="text-center font-medium text-md lg:text-xl mt-4">
                    {co_instrucor.NAME}
                  </div>

                  <div className="text-center text-neutral-500 mt-2">
                    {co_instrucor.DESIGNATION}
                  </div>

                  <div className="text-center text-neutral-500 my-2">
                    {co_instrucor.INSTITUTION}
                  </div>

                  <div className=" text-center flex flex-row items-center justify-center">
                    <MdOutlineMailOutline className="mr-2" />{" "}
                    {co_instrucor.EMAIL}
                  </div>
                  <div className="text-center text-neutral-500 mt-2">
                    {co_instrucor.SUBJECT}
                  </div>
                  <a
                    href={co_instrucor.PROFILE}
                    target="blank"
                    className="text-center cursor-pointer bg-blue-500 hover:bg-blue-600 text-white w-3/4 mx-auto rounded-md shadow-sm py-1 my-2 flex items-center justify-center"
                  >
                    View Profile
                    <BiChevronRight className="text-white text-2xl" />
                  </a>
                </div>
              );
            })}
          </div>
        </div>

        {/* Teaching Assistant */}
        <div className="mb-16 w-3/4 mx-auto" id="tas">
          <div className="text-xl mb-16 md:text-3xl text-center dark-text-white ">
            Teaching Assistants
          </div>
          <div className=" flex flex-row items-center justify-evenly flex-wrap">
            {tas.map((instructor, i) => {
              return (
                <div
                  key={i}
                  className="rounded-sm shadow-md pb-4 w-80 mx-4 mt-8 h-full px-2"
                >
                  <div
                    style={{
                      background: `url(${instructor.PHOTO})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      height: "300px",
                    }}
                  ></div>
                  <div className="text-center font-medium text-md lg:text-xl mt-4">
                    {instructor.NAME}
                  </div>
                  <div className="text-center text-neutral-500 mt-2">
                    {instructor.DESIGNATION}
                  </div>

                  <div className="text-center text-neutral-500 my-2">
                    {instructor.INSTITUTION}
                  </div>

                  <div className=" text-center flex flex-row items-center justify-center">
                    <MdOutlineMailOutline className="mr-2" /> {instructor.EMAIL}
                  </div>
                  <div className="text-center text-neutral-500 mt-2">
                    {instructor.SUBJECT}
                  </div>
                  <a
                    href={instructor.PROFILE}
                    target="blank"
                    className="text-center cursor-pointer bg-blue-500 hover:bg-blue-600 text-white w-3/4 mx-auto rounded-md shadow-sm py-1 my-2 flex items-center justify-center"
                  >
                    View Profile
                    <BiChevronRight className="text-white text-2xl" />
                  </a>
                </div>
              );
            })}
          </div>
        </div>

        {/* Commitee Panel */}
        <div id="committee">
          <div className="text-xl mb-16 md:text-3xl text-center dark-text-white">
            Co-ordination Commitee
          </div>
          <div className=" flex flex-row items-center justify-evenly flex-wrap">
            {commitee.map((instructor, i) => {
              return (
                <div key={i} className="rounded-sm shadow-md pb-4 w-80 mx-4 mt-8 h-full px-2">
                  <img
                    src={instructor.PHOTO}
                    alt={instructor.NAME}
                    className="img-fluid mx-auto"
                  />
                  <div className="text-center font-medium text-md lg:text-xl mt-4">
                    {instructor.NAME}
                  </div>
                  <div className="text-center text-neutral-500 mt-2">
                    {instructor.DESIGNATION}
                  </div>
                  <div className=" text-center flex flex-row items-center justify-center">
                    <MdOutlineMailOutline className="mr-2" /> {instructor.EMAIL}
                  </div>
                  <a
                    href={instructor.PROFILE}
                    target="blank"
                    className="text-center cursor-pointer bg-blue-500 hover:bg-blue-600 text-white w-3/4 mx-auto rounded-md shadow-sm py-1 my-2 flex items-center justify-center"
                  >
                    View Profile
                    <BiChevronRight className="text-white text-2xl" />
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default People;
