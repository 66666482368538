export const subs = [
  { sub_id: 1, sub_name: "Computer Engineering" },
  { sub_id: 2, sub_name: "Computer Science" },
  { sub_id: 3, sub_name: "Computer Science and Engineering" },
  { sub_id: 4, sub_name: "Electrical and Electronics Engineering" },
  { sub_id: 5, sub_name: "Electronic and Telecommunication Engineering" },
  { sub_id: 6, sub_name: "Physics" },
  { sub_id: 7, sub_name: "Mathematics" },
  { sub_id: 8, sub_name: "Chemistry" },
  { sub_id: 9, sub_name: "Statistics" },
  { sub_id: 10, sub_name: "Applied Mathematics" },
  { sub_id: 11, sub_name: "Applied Chemistry and Chemistry" },
  { sub_id: 12, sub_name: "Nuclear engineering" },
  { sub_id: 13, sub_name: "Robotics and Mechanics Engineering" },
  { sub_id: 14, sub_name: "Statistics Institute" },
  { sub_id: 15, sub_name: "Applied statistics" },
  { sub_id: 16, sub_name: "Software engineering" },
  { sub_id: 17, sub_name: "Architecture" },
  { sub_id: 18, sub_name: "Chemical Engineering   Science" },
  { sub_id: 19, sub_name: "Civil   Engineering" },
  { sub_id: 20, sub_name: "Civil Engineering" },
  { sub_id: 21, sub_name: "Water Resources Engineering" },
  { sub_id: 22, sub_name: "Food Engineering   Technology" },
  { sub_id: 23, sub_name: "Industrial   Engineering" },
  { sub_id: 24, sub_name: "Mechanical Engineering" },
  { sub_id: 25, sub_name: "Petroleum and Engineering" },
  { sub_id: 26, sub_name: "Chemical Engineering" },
  { sub_id: 27, sub_name: "Urban and Regional Planning" },
  { sub_id: 28, sub_name: "Naval Architecture and Marine Engineering" },
  { sub_id: 29, sub_name: "Biomedical Engineering" },
  { sub_id: 30, sub_name: "Materials and Metallurgical Engineering" },
  { sub_id: 31, sub_name: "Building Engineering and Construction Management" },
  { sub_id: 32, sub_name: "Electrical and Engineering" },
  { sub_id: 33, sub_name: "Glass and Ceramic Engineering" },
  { sub_id: 34, sub_name: "Mechatronics Engineering" },
  { sub_id: 35, sub_name: "Materials Science and" },
  { sub_id: 36, sub_name: "Chemical and Food Process Engineering" },
  { sub_id: 41, sub_name: "Other" },
];
