export const unis = [
  {
    uni_id: 1,
    uni_name: "University of Dhaka",
    uni_type: "Public",
    uni_website: "www.du.ac.bd",
  },
  {
    uni_id: 2,
    uni_name: "University of Rajshahi",
    uni_type: "Public",
    uni_website: "www.ru.ac.bd",
  },
  {
    uni_id: 3,
    uni_name: "Bangladesh Agricultural University",
    uni_type: "Public",
    uni_website: "www.bau.edu.bd",
  },
  {
    uni_id: 4,
    uni_name: "Bangladesh University of Engineering and",
    uni_type: "Public",
    uni_website: "www.buet.ac.bd",
  },
  {
    uni_id: 5,
    uni_name: "University of Chittagong",
    uni_type: "Public",
    uni_website: "www.cu.ac.bd",
  },
  {
    uni_id: 6,
    uni_name: "Jahangirnagar University",
    uni_type: "Public",
    uni_website: "www.juniv.edu",
  },
  {
    uni_id: 7,
    uni_name: "Islamic University, Bangladesh",
    uni_type: "Public",
    uni_website: "www.iu.ac.bd",
  },
  {
    uni_id: 8,
    uni_name: "Shahjalal University of Science and",
    uni_type: "Public",
    uni_website: "www.sust.edu",
  },
  {
    uni_id: 9,
    uni_name: "Khulna University",
    uni_type: "Public",
    uni_website: "www.ku.ac.bd",
  },
  {
    uni_id: 10,
    uni_name: "National University",
    uni_type: "Public",
    uni_website: "www.nu.edu.bd",
  },
  {
    uni_id: 11,
    uni_name: "Bangladesh Open University",
    uni_type: "Public",
    uni_website: "www.bou.ac.bd",
  },
  {
    uni_id: 12,
    uni_name: "Bangabandhu Sheikh Mujib Medical University",
    uni_type: "Public",
    uni_website: "www.bsmmu.edu.bd",
  },
  {
    uni_id: 13,
    uni_name: "Bangabandhu Sheikh Mujibur Rahman Agricultural University",
    uni_type: "Public",
    uni_website: "www.bsmrau.edu.bd",
  },
  {
    uni_id: 14,
    uni_name: "Hajee Mohammad Danesh Science and University",
    uni_type: "Public",
    uni_website: "www.hstu.ac.bd",
  },
  {
    uni_id: 15,
    uni_name: "Mawlana Bhashani Science and University",
    uni_type: "Public",
    uni_website: "www.mbstu.ac.bd",
  },
  {
    uni_id: 16,
    uni_name: "Patuakhali Science And Technology University",
    uni_type: "Public",
    uni_website: "www.pstu.ac.bd",
  },
  {
    uni_id: 17,
    uni_name: "Sher-e-Bangla Agricultural University",
    uni_type: "Public",
    uni_website: "www.sau.edu.bd",
  },
  {
    uni_id: 18,
    uni_name: "Chittagong University of Engineering and",
    uni_type: "Public",
    uni_website: "www.cuet.ac.bd",
  },
  {
    uni_id: 19,
    uni_name: "Rajshahi University of Engineering and",
    uni_type: "Public",
    uni_website: "www.ruet.ac.bd",
  },
  {
    uni_id: 20,
    uni_name: "Khulna University of Engineering and",
    uni_type: "Public",
    uni_website: "www.kuet.ac.bd",
  },
  {
    uni_id: 21,
    uni_name: "Dhaka University of Engineering and",
    uni_type: "Public",
    uni_website: "www.duet.ac.bd",
  },
  {
    uni_id: 22,
    uni_name: "Noakhali Science and University",
    uni_type: "Public",
    uni_website: "www.nstu.edu.bd",
  },
  {
    uni_id: 23,
    uni_name: "Jagannath University",
    uni_type: "Public",
    uni_website: "www.jnu.ac.bd",
  },
  {
    uni_id: 24,
    uni_name: "Comilla University",
    uni_type: "Public",
    uni_website: "www.cou.ac.bd",
  },
  {
    uni_id: 25,
    uni_name: "Jatiya Kabi Kazi Nazrul Islam University",
    uni_type: "Public",
    uni_website: "www.jkkniu.edu.bd",
  },
  {
    uni_id: 26,
    uni_name: "Chittagong Veterinary and Animal Sciences University",
    uni_type: "Public",
    uni_website: "www.cvasu.ac.bd",
  },
  {
    uni_id: 27,
    uni_name: "Sylhet Agricultural University",
    uni_type: "Public",
    uni_website: "www.sau.ac.bd",
  },
  {
    uni_id: 28,
    uni_name: "Jessore University of Science and",
    uni_type: "Public",
    uni_website: "www.just.edu.bd",
  },
  {
    uni_id: 29,
    uni_name: "Pabna University of Science and Technology",
    uni_type: "Public",
    uni_website: "www.pust.ac.bd",
  },
  {
    uni_id: 30,
    uni_name: "Begum Rokeya University, Rangpur",
    uni_type: "Public",
    uni_website: "www.brur.ac.bd",
  },
  {
    uni_id: 31,
    uni_name: "Bangladesh University of Professionals",
    uni_type: "Public",
    uni_website: "www.bup.edu.bd",
  },
  {
    uni_id: 32,
    uni_name: "Bangabandhu Sheikh Mujibur Rahman Science and University",
    uni_type: "Public",
    uni_website: "www.bsmrstu.edu.bd",
  },
  {
    uni_id: 33,
    uni_name: "Bangladesh University of Textiles",
    uni_type: "Public",
    uni_website: "www.butex.edu.bd",
  },
  {
    uni_id: 34,
    uni_name: "University of Barishal",
    uni_type: "Public",
    uni_website: "www.bu.ac.bd",
  },
  {
    uni_id: 35,
    uni_name: "Rangamati Science and Technology University",
    uni_type: "Public",
    uni_website: "www.rmstu.edu.bd",
  },
  {
    uni_id: 36,
    uni_name:
      "Bangabandhu Sheikh Mujibur Rahman Maritime University, Bangladesh",
    uni_type: "Public",
    uni_website: "www.bsmrmu.edu.bd",
  },
  {
    uni_id: 37,
    uni_name: "Islamic Arabic University",
    uni_type: "Public",
    uni_website: "www.iau.edu.bd",
  },
  {
    uni_id: 38,
    uni_name: "Chittagong Medical University",
    uni_type: "Public",
    uni_website: "www.cmu.edu.bd",
  },
  {
    uni_id: 39,
    uni_name: "Rajshahi Medical University",
    uni_type: "Public",
    uni_website: "www.rmu.edu.bd",
  },
  {
    uni_id: 40,
    uni_name: "Rabindra University, Bangladesh",
    uni_type: "Public",
    uni_website: "www.rub.ac.bd",
  },
  {
    uni_id: 41,
    uni_name:
      "Bangabandhu Sheikh Mujibur Rahman Digital University, Bangladesh",
    uni_type: "Public",
    uni_website: "www.bdu.ac.bd",
  },
  {
    uni_id: 42,
    uni_name: "Sheikh Hasina University",
    uni_type: "Public",
    uni_website: "https://shubd.net/",
  },
  {
    uni_id: 43,
    uni_name: "Khulna Agricultural University",
    uni_type: "Public",
    uni_website: "www.kau.edu.bd",
  },
  {
    uni_id: 44,
    uni_name:
      "Bangamata Sheikh Fojilatunnesa Mujib Science and Technology University",
    uni_type: "Public",
    uni_website: "https://bsfmstu.ac.bd/",
  },
  {
    uni_id: 45,
    uni_name: "Sylhet Medical University",
    uni_type: "Public",
    uni_website: "www.smu.edu.bd",
  },
  {
    uni_id: 46,
    uni_name:
      "Bangabandhu Sheikh Mujibur Rahman Aviation And Aerospace University (BSMRAAU)",
    uni_type: "Public",
    uni_website: "www.bsmraau.edu.bd",
  },
  {
    uni_id: 47,
    uni_name: "Chandpur Science and Technology University",
    uni_type: "Public",
    uni_website: ".",
  },
  {
    uni_id: 48,
    uni_name: "Bangabandhu Sheikh Mujibur Rahman University, Kishoreganj",
    uni_type: "Public",
    uni_website: "bsmru.ac.bd.",
  },
  {
    uni_id: 49,
    uni_name: "Hobiganj Agricultural University",
    uni_type: "Public",
    uni_website: "www.hau.ac.bd",
  },
  {
    uni_id: 50,
    uni_name: "Sheikh Hasina Medical University, Khulna",
    uni_type: "Public",
  },
  {
    uni_id: 51,
    uni_name: "Kurigram Agricultural University",
    uni_type: "Public",
    uni_website: ".",
  },
  {
    uni_id: 52,
    uni_name: "Sunamganj Science and Technology University",
    uni_type: "Public",
    uni_website: ".",
  },
  {
    uni_id: 53,
    uni_name:
      "Bangabandhu Sheikh Mujibur Rahman Science and University, Pirojpur",
    uni_type: "Public",
    uni_website: ".",
  },
  { uni_id: 62, uni_name: "Other", uni_type: "Public" },
];
